<template>
  <div class="secciones-container">
    <app-header rootClassName="header-root-class-name5"></app-header>
    <div class="secciones-max-width">
      <div class="secciones-text-container">
        <h2 class="secciones-text Heading2">Nuestras Sedes</h2>
        <span class="secciones-text1">
          Nuestra institución educativa cuenta con un total de 8 sedes, siendo una
          de ellas ubicada en un entorno urbano (Sede Urbana) y las otras 7
          ubicadas en áreas rurales (Sedes Rurales). En estas sedes, ofrecemos una
          amplia gama de grados académicos para satisfacer las necesidades de
          nuestros estudiantes. Los grados van desde el nivel preescolar (grado 0)
          hasta el grado 5 en todas nuestras sedes. Además, en nuestra Sede
          Urbana, brindamos el nivel de Bachillerato, que abarca desde el grado 6
          hasta el grado 11. De esta manera, estamos comprometidos en ofrecer una
          educación completa y accesible para todos nuestros estudiantes, tanto en
          áreas urbanas como rurales.
        </span>
      </div>
      <div class="secciones-tab-selector-cards-container">
        <app-sedes
          image_src="/cole-400h.jpg"
          project_title="Sede Principal"
          rootClassName="sedes-root-class-name"
        ></app-sedes>
        <app-sedes
          image_src="/sedeurbana-400h.jpg"
          project_title="Sede Básica Primaria"
          rootClassName="sedes-root-class-name10"
        ></app-sedes>
        <app-sedes
          image_src="/bolivar-400h.jpg"
          project_title="Sede Bolívar"
          rootClassName="sedes-root-class-name9"
        ></app-sedes>
        <app-sedes
          image_src="/costarica-400h.jpg"
          project_title="Sede Chamizal"
          rootClassName="sedes-root-class-name8"
        ></app-sedes>
        <app-sedes
          image_src="/sedecostarica-400h.jpg"
          project_title="Sede Costa Rica"
          rootClassName="sedes-root-class-name5"
        ></app-sedes>
        <app-sedes
          image_src="/llanitos-400h.jpg"
          project_title="Sede Llanitos"
          rootClassName="sedes-root-class-name4"
        ></app-sedes>
        <app-sedes
          image_src="/llanogrande1-400h.jpg"
          project_title="Sede Llano Grande Montones"
          rootClassName="sedes-root-class-name3"
        ></app-sedes>
        <app-sedes
          image_src="/sanantonio-400h.jpg"
          project_title="Sede San Antonio"
          rootClassName="sedes-root-class-name2"
        ></app-sedes>
        <app-sedes
          image_src="/santabarbara-400h.jpg"
          project_title="Sede Santa Barbara"
          rootClassName="sedes-root-class-name1"
        ></app-sedes>
        <app-sedes
          image_src="/sanluis-400h.jpg"
          project_title="Sede San Luis"
          rootClassName="sedes-root-class-name6"
        ></app-sedes>
        <app-sedes
          image_src="/santander-400h.jpg"
          project_title="Sede Santander"
          rootClassName="sedes-root-class-name7"
        ></app-sedes>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppSedes from '../components/sedes'
import AppFooter from '../components/footer'

export default {
  name: 'Secciones',
  components: {
    AppHeader,
    AppSedes,
    AppFooter,
  },
  metaInfo: {
    title: 'Secciones - ColegioTasco',
    meta: [
      {
        property: 'og:title',
        content: 'Secciones - ColegioTasco',
      },
    ],
  },
}
</script>

<style scoped>
.secciones-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.secciones-max-width {
  max-width: var(--dl-size-size-default);
  flex-direction: column;
}
.secciones-text-container {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  flex-direction: column;
}
.secciones-text {
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.secciones-text1 {
  color: var(--dl-color-scheme-black80);
  padding: var(--dl-space-space-fiveunits);
  text-align: center;
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.secciones-tab-selector-cards-container {
  width: 100%;
  display: grid;
  grid-row-gap: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  grid-column-gap: var(--dl-space-space-twounits);
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
}
@media(max-width: 1366px) {
  .secciones-tab-selector-cards-container {
    padding: var(--dl-space-space-twounits);
  }
}
@media(max-width: 991px) {
  .secciones-tab-selector-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media(max-width: 767px) {
  .secciones-tab-selector-cards-container {
    grid-template-columns: 1fr;
  }
}
@media(max-width: 393px) {
  .secciones-max-width {
    width: 100%;
  }
  .secciones-text1 {
    padding: var(--dl-space-space-oneandhalfunits);
    text-align: justify;
  }
  .secciones-tab-selector-cards-container {
    grid-template-columns: 1fr;
  }
}
</style>
