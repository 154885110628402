<template>
  <div class="mision-vision-feature-card" v-bind:class="rootClassName">
    <div class="mision-vision-container">
      <h2 class="mision-vision-text">{{ title }}</h2>
      <span class="mision-vision-text1">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MisionVision',
  props: {
    title: {
      type: String,
      default: 'Lorem ipsum',
    },
    description: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.mision-vision-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.mision-vision-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.mision-vision-text {
  color: var(--dl-color-text-white);
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.mision-vision-text1 {
  color: var(--dl-color-text-white);
  font-size: 1.5rem;
}




@media(max-width: 767px) {
  .mision-vision-feature-card {
    flex-direction: row;
  }
  .mision-vision-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .mision-vision-container {
    flex-direction: column;
  }
}
</style>
