<template>
  <nav class="link-menu-nav" v-bind:class="rootClassName">
    <router-link to="/" class="link-menu-text1">{{ menu1 }}</router-link>
    <router-link to="/nosotros" class="link-menu-text2">{{ menu2 }}</router-link>
    <router-link to="/secciones" class="link-menu-text21">
      {{ menu3 }}
    </router-link>
    <router-link to="/modalidades" class="link-menu-text3">
      {{ menu4 }}
    </router-link>
    <router-link to="/docentes" class="link-menu-text4">{{ menu5 }}</router-link>
    <router-link to="/normativa" class="link-menu-text4">{{ menu6 }}</router-link>
  </nav>
</template>

<script>
export default {
  name: 'LinkMenu',
  props: {
    menu2: {
      type: String,
      default: 'Nosotros',
    },
    menu3: {
      type: String,
      default: 'Secciones',
    },
    menu1: {
      type: String,
      default: 'Inicio',
    },
    menu5: {
      type: String,
      default: 'Docentes',
    },
    rootClassName: String,
    menu6: {
      type: String,
      default: 'Normativa',
    },
    menu4: {
      type: String,
      default: 'Areas Técnicas',
    },
  },
}
</script>

<style scoped>
.link-menu-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.link-menu-text1 {
  color: var(--dl-color-text-white);
  text-decoration: none;
}
.link-menu-text2 {
  color: var(--dl-color-text-white);
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.link-menu-text21 {
  color: var(--dl-color-text-white);
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.link-menu-text3 {
  color: var(--dl-color-text-white);
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.link-menu-text4 {
  color: var(--dl-color-text-white);
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.link-menu-text5 {
  color: var(--dl-color-text-white);
  margin-left: var(--dl-space-space-twounits);
}








.link-menu-root-class-name10 {
  flex: 1;
  align-self: center;
}
@media(max-width: 1366px) {
  .link-menu-text1 {
    color: #fffefe;
  }
  .link-menu-text2 {
    color: #ffffff;
  }
  .link-menu-text21 {
    color: #ffffff;
  }
  .link-menu-text3 {
    color: #ffffff;
  }
  .link-menu-text4 {
    color: #ffffff;
  }
  .link-menu-text5 {
    color: #ffffff;
  }
}
@media(max-width: 991px) {
  .link-menu-nav {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
  .link-menu-text1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .link-menu-text2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .link-menu-text21 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .link-menu-text3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .link-menu-text4 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .link-menu-text5 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}
</style>
