<template>
  <div class="calendario-container">
    <app-header rootClassName="header-root-class-name9"></app-header>
    <div class="calendario-gallery">
      <h1 class="calendario-text">Nuestro Calendario</h1>
      <span class="calendario-text01">
        Este calendario está diseñado exclusivamente para proporcionarte una
        visión rápida y clara de las fechas importantes que necesitas recordar.
      </span>
      <div class="calendario-testimonial">
        <div class="calendario-container01">
          <div class="calendario-step">
            <div class="calendario-container02">
              <span class="calendario-text02">20</span>
              <span class="calendario-text03">JUL</span>
              <span class="calendario-text04">2023</span>
            </div>
            <div class="calendario-container03">
              <span class="calendario-text05">
                Entrega Informes Segundo Periodo
              </span>
              <span class="calendario-text06">
                <span>Jueves 20 de Julio - Polideportivo Del Colegio</span>
                <br />
              </span>
            </div>
          </div>
          <div class="calendario-divider"></div>
          <div class="calendario-step1">
            <div class="calendario-container04">
              <span class="calendario-text09">11</span>
              <span class="calendario-text10">JUL</span>
              <span class="calendario-text11">2023</span>
            </div>
            <div class="calendario-container05">
              <span class="calendario-text12">
                Inicio Clase Regreso de Vacaciones
              </span>
              <span class="calendario-text13">
                <span>Martes 11 de Julio - Polideportivo Del Colegio</span>
                <br />
              </span>
            </div>
          </div>
          <div class="calendario-divider1"></div>
          <div class="calendario-step2">
            <div class="calendario-container06">
              <span class="calendario-text16">20</span>
              <span class="calendario-text17">
                <span>ABR</span>
                <br />
              </span>
              <span class="calendario-text20">2023</span>
            </div>
            <div class="calendario-container07">
              <span class="calendario-text21">
                Entrega Informes Primer Periodo
              </span>
              <span class="calendario-text22">
                <span>Martes 11 de Julio - Polideportivo Del Colegio</span>
                <br />
              </span>
            </div>
          </div>
          <div class="calendario-divider2"></div>
          <div class="calendario-step3">
            <div class="calendario-container08">
              <span class="calendario-text25">30</span>
              <span class="calendario-text26">
                <span>ENE</span>
                <br />
              </span>
              <span class="calendario-text29">2023</span>
            </div>
            <div class="calendario-container09">
              <span class="calendario-text30">Inicio de Clases Año 2023</span>
              <span class="calendario-text31">
                <span>
                  Lunes 30 de enero - 8:00 AM - No se cuenta con el servicio de
                  PAE hasta que haya confirmación de las entidades respectivas
                </span>
                <br />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name3"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppFooter from '../components/footer'

export default {
  name: 'Calendario',
  components: {
    AppHeader,
    AppFooter,
  },
  metaInfo: {
    title: 'Calendario - ColegioTasco',
    meta: [
      {
        property: 'og:title',
        content: 'Calendario - ColegioTasco',
      },
    ],
  },
}
</script>

<style scoped>
.calendario-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.calendario-gallery {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-default);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.calendario-text {
  color: var(--dl-color-main-primary);
  font-size: 3rem;
  text-align: center;
}
.calendario-text01 {
  color: var(--dl-color-gray-700);
  font-size: 25px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.calendario-testimonial {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #485158;
}
.calendario-container01 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-default);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.calendario-step {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits1);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.calendario-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.calendario-text02 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-text03 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-text04 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-container03 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.calendario-text05 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.calendario-text06 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
.calendario-divider {
  flex: 0 0 auto;
  width: 100%;
  height: 3px;
  display: flex;
  opacity: 0.25;
  margin-top: 4rem;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: 4rem;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.calendario-step1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits1);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.calendario-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.calendario-text09 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-text10 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-text11 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-container05 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.calendario-text12 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.calendario-text13 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
.calendario-divider1 {
  flex: 0 0 auto;
  width: 100%;
  height: 3px;
  display: flex;
  opacity: 0.25;
  margin-top: 4rem;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: 4rem;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.calendario-step2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits1);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.calendario-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.calendario-text16 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-text17 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-text20 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-container07 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.calendario-text21 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.calendario-text22 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
.calendario-divider2 {
  flex: 0 0 auto;
  width: 100%;
  height: 3px;
  display: flex;
  opacity: 0.25;
  margin-top: 4rem;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: 4rem;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.calendario-step3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits1);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.calendario-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.calendario-text25 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-text26 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-text29 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.calendario-container09 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.calendario-text30 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.calendario-text31 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
@media(max-width: 1440px) {
  .calendario-testimonial {
    background-color: transparent;
  }
  .calendario-container01 {
    padding: var(--dl-space-space-unit);
  }
  .calendario-step {
    padding-top: var(--dl-space-space-unit);
    margin-bottom: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .calendario-container02 {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .calendario-text02 {
    color: var(--dl-color-main-primary);
  }
  .calendario-text03 {
    color: var(--dl-color-main-primary);
    font-size: 50px;
  }
  .calendario-text04 {
    color: var(--dl-color-main-primary);
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 61px;
  }
  .calendario-container03 {
    justify-content: center;
  }
  .calendario-text05 {
    color: var(--dl-color-gray-black);
  }
  .calendario-text06 {
    color: var(--dl-color-gray-black);
  }
  .calendario-divider {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .calendario-step1 {
    padding-top: var(--dl-space-space-unit);
    margin-bottom: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .calendario-container04 {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .calendario-text09 {
    color: var(--dl-color-main-primary);
  }
  .calendario-text10 {
    color: var(--dl-color-main-primary);
    font-size: 50px;
  }
  .calendario-text11 {
    color: var(--dl-color-main-primary);
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 61px;
  }
  .calendario-container05 {
    justify-content: center;
  }
  .calendario-text12 {
    color: var(--dl-color-gray-black);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
  }
  .calendario-text13 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
  }
  .calendario-divider1 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .calendario-step2 {
    padding-top: var(--dl-space-space-unit);
    margin-bottom: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .calendario-container06 {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .calendario-text16 {
    color: var(--dl-color-main-primary);
  }
  .calendario-text17 {
    color: var(--dl-color-main-primary);
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 61px;
  }
  .calendario-text20 {
    color: var(--dl-color-main-primary);
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 61px;
  }
  .calendario-container07 {
    justify-content: center;
  }
  .calendario-text21 {
    color: var(--dl-color-gray-black);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
  }
  .calendario-text22 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
  }
  .calendario-divider2 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .calendario-step3 {
    padding-top: var(--dl-space-space-unit);
    margin-bottom: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .calendario-container08 {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .calendario-text25 {
    color: var(--dl-color-main-primary);
  }
  .calendario-text26 {
    color: var(--dl-color-main-primary);
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 61px;
  }
  .calendario-text29 {
    color: var(--dl-color-main-primary);
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 61px;
  }
  .calendario-container09 {
    justify-content: center;
  }
  .calendario-text30 {
    color: var(--dl-color-gray-black);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
  }
  .calendario-text31 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
  }
}
@media(max-width: 1366px) {
  .calendario-text05 {
    color: var(--dl-color-scheme-white);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
  }
  .calendario-text06 {
    color: var(--dl-color-scheme-white80);
    font-size: 20px;
  }
  .calendario-text12 {
    color: var(--dl-color-scheme-white);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
  }
  .calendario-text13 {
    color: var(--dl-color-scheme-white80);
    font-size: 20px;
  }
  .calendario-text21 {
    color: var(--dl-color-scheme-white);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
  }
  .calendario-text22 {
    color: var(--dl-color-scheme-white80);
    font-size: 20px;
  }
  .calendario-text30 {
    color: var(--dl-color-scheme-white);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
  }
  .calendario-text31 {
    color: var(--dl-color-scheme-white80);
    font-size: 20px;
  }
}
@media(max-width: 991px) {
  .calendario-text01 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .calendario-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .calendario-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .calendario-container01 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .calendario-text02 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .calendario-text03 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .calendario-text04 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .calendario-divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .calendario-text09 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .calendario-text10 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .calendario-text11 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .calendario-divider1 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .calendario-text16 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .calendario-text17 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .calendario-text20 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .calendario-divider2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .calendario-text25 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .calendario-text26 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .calendario-text29 {
    margin-right: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 479px) {
  .calendario-gallery {
    padding: var(--dl-space-space-unit);
  }
  .calendario-container01 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .calendario-text02 {
    margin-right: var(--dl-space-space-twounits);
  }
  .calendario-text03 {
    margin-right: var(--dl-space-space-twounits);
  }
  .calendario-text04 {
    margin-right: var(--dl-space-space-twounits);
  }
  .calendario-text09 {
    margin-right: var(--dl-space-space-twounits);
  }
  .calendario-text10 {
    margin-right: var(--dl-space-space-twounits);
  }
  .calendario-text11 {
    margin-right: var(--dl-space-space-twounits);
  }
  .calendario-text16 {
    margin-right: var(--dl-space-space-twounits);
  }
  .calendario-text17 {
    margin-right: var(--dl-space-space-twounits);
  }
  .calendario-text20 {
    margin-right: var(--dl-space-space-twounits);
  }
  .calendario-text25 {
    margin-right: var(--dl-space-space-twounits);
  }
  .calendario-text26 {
    margin-right: var(--dl-space-space-twounits);
  }
  .calendario-text29 {
    margin-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 393px) {
  .calendario-container01 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .calendario-text02 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .calendario-text03 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .calendario-text04 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .calendario-text09 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .calendario-text10 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .calendario-text11 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .calendario-text16 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .calendario-text17 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .calendario-text20 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .calendario-text25 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .calendario-text26 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .calendario-text29 {
    margin-right: var(--dl-space-space-halfunit);
  }
}
</style>
