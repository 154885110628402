import { render, staticRenderFns } from "./descubre.vue?vue&type=template&id=fe3b1064&scoped=true&"
import script from "./descubre.vue?vue&type=script&lang=js&"
export * from "./descubre.vue?vue&type=script&lang=js&"
import style0 from "./descubre.vue?vue&type=style&index=0&id=fe3b1064&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe3b1064",
  null
  
)

export default component.exports