<template>
  <div class="modalidades-container">
    <app-header rootClassName="header-root-class-name6"></app-header>
    <h1 class="modalidades-text">Areas Técnicas</h1>
    <div class="modalidades-section">
      <div class="modalidades-content">
        <div class="modalidades-header">
          <h2 class="modalidades-heading">
            Patronaje Industrial de Prendas de Vestir
          </h2>
          <p class="modalidades-capton">
            El patronaje industrial de prendas de vestir es un proceso esencial en
            la industria de la moda y la confección que se encarga de crear
            patrones precisos y técnicos para la fabricación de ropa. Esta
            disciplina combina la creatividad y la precisión técnica para
            transformar diseños conceptuales en patrones que servirán como guía
            para cortar y coser las telas y materiales necesarios para
            confeccionar una prenda de vestir.
          </p>
        </div>
      </div>
      <img alt="image" src="/patronaje-1400w.jpg" class="modalidades-image" />
    </div>
    <div class="modalidades-testimonial">
      <div class="modalidades-container01">
        <h1 class="modalidades-text01">
          <span>Habilidades y Conocimientos</span>
          <br />
        </h1>
        <div class="modalidades-step">
          <span class="modalidades-text04">01</span>
          <div class="modalidades-container02">
            <span class="modalidades-text05">Diseño y Conceptualización</span>
            <span class="modalidades-text06">
              El proceso de patronaje industrial comienza con la conceptualización
              de una prenda de vestir. Los diseñadores crean bocetos y conceptos
              de moda que luego se transforman en patrones técnicos.
            </span>
          </div>
        </div>
        <div class="modalidades-step1">
          <span class="modalidades-text07">02</span>
          <div class="modalidades-container03">
            <span class="modalidades-text08">Construcción de Patrones</span>
            <span class="modalidades-text09">
              Los patrones se crean utilizando técnicas matemáticas y geométricas.
              Esto implica trazar líneas, curvas y formas que representen cada
              pieza de tela que compondrá la prenda, como la parte delantera,
              trasera, mangas, cuellos, etc
            </span>
          </div>
        </div>
        <div class="modalidades-step2">
          <span class="modalidades-text10">03</span>
          <div class="modalidades-container04">
            <span class="modalidades-text11">Confección</span>
            <span class="modalidades-text12">
              Las piezas cortadas se ensamblan utilizando máquinas de coser y
              técnicas de costura específicas. Aquí es donde las piezas toman
              forma y se convierten en una prenda completa.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="modalidades-section1">
      <div class="modalidades-content1">
        <div class="modalidades-header1">
          <h2 class="modalidades-heading1">
            Técnico Mecánico de Maquinaria Industrial
          </h2>
          <p class="modalidades-capton1">
            Un técnico mecánico de maquinaria industrial es un profesional
            altamente capacitado que se especializa en el mantenimiento,
            reparación y operación de maquinaria industrial utilizada en diversas
            industrias, como la manufactura, la construcción, la minería, la
            energía, la automoción y muchas otras. Estos técnicos desempeñan un
            papel crucial para garantizar que las máquinas y equipos industriales
            funcionen de manera eficiente y segura.
          </p>
        </div>
      </div>
      <img alt="image" src="/mecanica-1400w.jpeg" class="modalidades-image1" />
    </div>
    <div class="modalidades-testimonial1">
      <div class="modalidades-container05">
        <h1 class="modalidades-text13">
          <span>Habilidades y Conocimientos</span>
          <br />
        </h1>
        <div class="modalidades-step3">
          <span class="modalidades-text16">01</span>
          <div class="modalidades-container06">
            <span class="modalidades-text17">Mantenimiento Preventivo</span>
            <span class="modalidades-text18">
              Realizan inspecciones regulares para identificar y abordar posibles
              problemas antes de que se conviertan en fallas graves. Lubrican,
              ajustan y reemplazan componentes según sea necesario.
            </span>
          </div>
        </div>
        <div class="modalidades-step4">
          <span class="modalidades-text19">02</span>
          <div class="modalidades-container07">
            <span class="modalidades-text20">Lectura de Planos</span>
            <span class="modalidades-text21">
              Pueden interpretar planos técnicos y diagramas de circuitos para
              comprender la estructura y el funcionamiento de la maquinaria.
            </span>
          </div>
        </div>
        <div class="modalidades-step5">
          <span class="modalidades-text22">03</span>
          <div class="modalidades-container08">
            <span class="modalidades-text23">Resolución de Problemas</span>
            <span class="modalidades-text24">
              Son capaces de diagnosticar problemas y desarrollar soluciones
              efectivas en situaciones de fallo o mal funcionamiento de la
              maquinaria.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="modalidades-section2">
      <div class="modalidades-content2">
        <div class="modalidades-header2">
          <h2 class="modalidades-heading2">
            Técnico en Contabilización de Operaciones Comerciales y Financieras
          </h2>
          <p class="modalidades-capton2">
            Un técnico en contabilización de operaciones comerciales y financieras
            es un profesional especializado en llevar a cabo tareas relacionadas
            con la contabilidad en el ámbito empresarial. Su trabajo consiste en
            registrar, analizar y mantener un control adecuado de las
            transacciones comerciales y financieras de una empresa.
          </p>
        </div>
      </div>
      <img
        alt="image"
        src="/contabilizacion-1400w.jpg"
        class="modalidades-image2"
      />
    </div>
    <div class="modalidades-testimonial2">
      <div class="modalidades-container09">
        <h1 class="modalidades-text25">
          <span>Habilidades y Conocimientos</span>
          <br />
        </h1>
        <div class="modalidades-step6">
          <span class="modalidades-text28">01</span>
          <div class="modalidades-container10">
            <span class="modalidades-text29">Conocimientos Contables</span>
            <span class="modalidades-text30">
              Poseer un sólido conocimiento de los principios contables y las
              normas financieras aplicables.
            </span>
          </div>
        </div>
        <div class="modalidades-step7">
          <span class="modalidades-text31">02</span>
          <div class="modalidades-container11">
            <span class="modalidades-text32">Habilidades Analíticas</span>
            <span class="modalidades-text33">
              <span>
                Ser capaz de analizar datos financieros y detectar tendencias o
                áreas de mejora.
              </span>
              <br />
            </span>
          </div>
        </div>
        <div class="modalidades-step8">
          <span class="modalidades-text36">03</span>
          <div class="modalidades-container12">
            <span class="modalidades-text37">Análisis de Costos</span>
            <span class="modalidades-text38">
              <span>
                Analizar los costos de producción y operativos para identificar
                oportunidades de ahorro y mejorar la eficiencia.
              </span>
              <br />
            </span>
          </div>
        </div>
      </div>
    </div>
    <h1 class="modalidades-text41">
      &quot;Una Educación Incluyente y de Oportunidades&quot;
    </h1>
    <app-footer rootClassName="footer-root-class-name"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppFooter from '../components/footer'

export default {
  name: 'Modalidades',
  components: {
    AppHeader,
    AppFooter,
  },
  metaInfo: {
    title: 'Modalidades - ColegioTasco',
    meta: [
      {
        property: 'og:title',
        content: 'Modalidades - ColegioTasco',
      },
    ],
  },
}
</script>

<style scoped>
.modalidades-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.modalidades-text {
  font-size: 3rem;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.modalidades-section {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-default);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.modalidades-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.modalidades-header {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.modalidades-heading {
  font-size: 50px;
  max-width: 600px;
  font-style: normal;
  font-weight: 600;
}
.modalidades-capton {
  font-size: 22px;
  max-width: 600px;
  line-height: 27px;
}
.modalidades-image {
  width: 500px;
  height: 400px;
  object-fit: fill;
}
.modalidades-testimonial {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #485158;
}
.modalidades-container01 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-default);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.modalidades-text01 {
  color: var(--dl-color-gray-white);
  font-size: 3em;
  margin-bottom: var(--dl-space-space-threeunits);
}
.modalidades-step {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits1);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modalidades-text04 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.modalidades-container02 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modalidades-text05 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.modalidades-text06 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
.modalidades-step1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits1);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modalidades-text07 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.modalidades-container03 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modalidades-text08 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.modalidades-text09 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
.modalidades-step2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modalidades-text10 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.modalidades-container04 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modalidades-text11 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.modalidades-text12 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
.modalidades-section1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-default);
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.modalidades-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.modalidades-header1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.modalidades-heading1 {
  font-size: 50px;
  max-width: 600px;
  font-style: normal;
  font-weight: 600;
}
.modalidades-capton1 {
  font-size: 22px;
  max-width: 600px;
  line-height: 27px;
}
.modalidades-image1 {
  width: 500px;
  object-fit: cover;
}
.modalidades-testimonial1 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #485158;
}
.modalidades-container05 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-default);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.modalidades-text13 {
  color: var(--dl-color-gray-white);
  font-size: 3em;
  margin-bottom: var(--dl-space-space-threeunits);
}
.modalidades-step3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits1);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modalidades-text16 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.modalidades-container06 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modalidades-text17 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.modalidades-text18 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.modalidades-step4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits1);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modalidades-text19 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.modalidades-container07 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modalidades-text20 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.modalidades-text21 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
.modalidades-step5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modalidades-text22 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.modalidades-container08 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modalidades-text23 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.modalidades-text24 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
.modalidades-section2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-default);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.modalidades-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.modalidades-header2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.modalidades-heading2 {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  font-weight: 600;
}
.modalidades-capton2 {
  font-size: 22px;
  max-width: 600px;
  line-height: 27px;
}
.modalidades-image2 {
  width: 580px;
  height: 500px;
  object-fit: fill;
}
.modalidades-testimonial2 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #485158;
}
.modalidades-container09 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-default);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.modalidades-text25 {
  color: var(--dl-color-gray-white);
  font-size: 3em;
  margin-bottom: var(--dl-space-space-threeunits);
}
.modalidades-step6 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits1);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modalidades-text28 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.modalidades-container10 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modalidades-text29 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.modalidades-text30 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
.modalidades-step7 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits1);
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modalidades-text31 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.modalidades-container11 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modalidades-text32 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.modalidades-text33 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
.modalidades-step8 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  flex-direction: row;
  padding-bottom: 53px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modalidades-text36 {
  color: var(--dl-color-scheme-white);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-right: 180px;
}
.modalidades-container12 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modalidades-text37 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: var(--dl-space-space-unit);
}
.modalidades-text38 {
  color: var(--dl-color-scheme-white80);
  font-size: 20px;
}
.modalidades-text41 {
  color: var(--dl-color-main-primary);
  font-size: 3rem;
  font-style: italic;
  margin-top: var(--dl-space-space-sixunits);
  font-family: Arial Black;
  font-weight: 900;
  margin-bottom: var(--dl-space-space-sixunits);
}
@media(max-width: 1366px) {
  .modalidades-section {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .modalidades-text01 {
    color: var(--dl-color-gray-white);
  }
  .modalidades-section1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .modalidades-text13 {
    color: var(--dl-color-gray-white);
  }
  .modalidades-section2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .modalidades-text25 {
    color: var(--dl-color-gray-white);
  }
}
@media(max-width: 991px) {
  .modalidades-section {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .modalidades-image {
    width: 100%;
  }
  .modalidades-text01 {
    text-align: center;
  }
  .modalidades-section1 {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .modalidades-image1 {
    width: 100%;
  }
  .modalidades-text13 {
    text-align: center;
  }
  .modalidades-section2 {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .modalidades-image2 {
    width: 100%;
  }
  .modalidades-text25 {
    text-align: center;
  }
  .modalidades-text41 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .modalidades-content {
    gap: var(--dl-space-space-unit);
  }
  .modalidades-heading {
    font-size: 30px;
    line-height: 36px;
  }
  .modalidades-capton {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .modalidades-container01 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .modalidades-text04 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .modalidades-text07 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .modalidades-text10 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .modalidades-heading1 {
    font-size: 30px;
    line-height: 36px;
  }
  .modalidades-capton1 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .modalidades-container05 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .modalidades-text16 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .modalidades-text19 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .modalidades-text22 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .modalidades-heading2 {
    font-size: 30px;
    line-height: 36px;
  }
  .modalidades-capton2 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .modalidades-container09 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .modalidades-text28 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .modalidades-text31 {
    margin-right: var(--dl-space-space-fourunits);
  }
  .modalidades-text36 {
    margin-right: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 720px) {
  .modalidades-text41 {
    width: 100%;
    font-size: 2.8rem;
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 479px) {
  .modalidades-container01 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .modalidades-text04 {
    margin-right: var(--dl-space-space-twounits);
  }
  .modalidades-text07 {
    margin-right: var(--dl-space-space-twounits);
  }
  .modalidades-text10 {
    margin-right: var(--dl-space-space-twounits);
  }
  .modalidades-container05 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .modalidades-text16 {
    margin-right: var(--dl-space-space-twounits);
  }
  .modalidades-text19 {
    margin-right: var(--dl-space-space-twounits);
  }
  .modalidades-text22 {
    margin-right: var(--dl-space-space-twounits);
  }
  .modalidades-container09 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .modalidades-text28 {
    margin-right: var(--dl-space-space-twounits);
  }
  .modalidades-text31 {
    margin-right: var(--dl-space-space-twounits);
  }
  .modalidades-text36 {
    margin-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 393px) {
  .modalidades-section {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .modalidades-content {
    width: 100%;
  }
  .modalidades-header {
    width: 100%;
  }
  .modalidades-heading {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .modalidades-capton {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .modalidades-image {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .modalidades-container01 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .modalidades-text04 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .modalidades-text07 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .modalidades-text10 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .modalidades-section1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .modalidades-content1 {
    width: 100%;
  }
  .modalidades-heading1 {
    padding: var(--dl-space-space-unit);
  }
  .modalidades-capton1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .modalidades-image1 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .modalidades-container05 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .modalidades-text16 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .modalidades-text19 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .modalidades-text22 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .modalidades-section2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .modalidades-content2 {
    width: 100%;
  }
  .modalidades-header2 {
    width: 100%;
  }
  .modalidades-heading2 {
    width: 100%;
    font-size: 30px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .modalidades-capton2 {
    width: 100%;
    max-width: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .modalidades-image2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .modalidades-container09 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .modalidades-text28 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .modalidades-text31 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .modalidades-text36 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .modalidades-text41 {
    width: 100%;
    font-size: 2.5rem;
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
