import { render, staticRenderFns } from "./docente.vue?vue&type=template&id=65b05913&scoped=true&"
import script from "./docente.vue?vue&type=script&lang=js&"
export * from "./docente.vue?vue&type=script&lang=js&"
import style0 from "./docente.vue?vue&type=style&index=0&id=65b05913&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b05913",
  null
  
)

export default component.exports