<template>
  <div class="normativa-container">
    <app-header rootClassName="header-root-class-name10"></app-header>
    <div class="normativa-gallery">
      <h1 class="normativa-text">Conoce nuestra normativa</h1>
      <span class="normativa-text1">
        Bienvenidos a nuestra Sección de Normativa, donde encontrarás información
        detallada y actualizada sobre las regulaciones y pautas vigentes en tu
        área de interés.
        <span v-html="raw5nxq"></span>
      </span>
      <div class="normativa-container1">
        <h1 class="normativa-text2">Manual de Convivencia</h1>
        <div class="normativa-gallery1">
          <gallery-card1
            image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t1.6435-9/97440205_1397895317069110_6988401111921065984_n.jpg?_nc_cat=111&amp;ccb=1-7&amp;_nc_sid=8bfeb9&amp;_nc_eui2=AeHtf3L26WC1EJYT0E2Ax5tSR2uFh7qRxa1Ha4WHupHFrRqdAn-Dmnvf8S2LSHOWL8bCrt2u_uh8yb0EfmB4mIH8&amp;_nc_ohc=G3anf4N2JRIAX8gCZmF&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfBFiT87TK_KgqSKfSZNReteUvspzZvOTKU5Ftv18zkyng&amp;oe=6526A32F"
            rootClassName="rootClassName2"
            title=" "
            subtitle=" "
          ></gallery-card1>
        </div>
      </div>
      <div class="normativa-container2">
        <h1 class="normativa-text3">PEI - Proyecto Educativo Institucional</h1>
        <div class="normativa-gallery2">
          <gallery-card1
            image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t1.6435-9/89924295_1349839108541398_8704288358249005056_n.jpg?_nc_cat=111&amp;ccb=1-7&amp;_nc_sid=8bfeb9&amp;_nc_eui2=AeG9BwayfGW-dEQ430jd7WRNMmkl_8Vk0JAyaSX_xWTQkO_F9_TW_v88QIVvEIABmKjqocWBhuHynjllxy_7crUQ&amp;_nc_ohc=GcIYpONO5qsAX-ExjwF&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfAh-MMokoIKZ3rY1Xk5eimMDi8Nf4b_hkoDG8PBEWcDdA&amp;oe=65269BCA"
            rootClassName="rootClassName11"
            title=" "
            subtitle=" "
          ></gallery-card1>
        </div>
      </div>
      <div class="normativa-container3">
        <h1 class="normativa-text4">Comunicados</h1>
        <div class="normativa-gallery3">
          <gallery-card1
            rootClassName="rootClassName6"
            image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/217713090_461043168571496_8787260933231316593_n.jpg?_nc_cat=107&amp;ccb=1-7&amp;_nc_sid=5614bc&amp;_nc_eui2=AeFlhy6Ut3R6Jnb3ihU5PySkZSD2hiXpCi9lIPaGJekKL7KMBcsVF76WGoEBdTgPFnuRcokQQVcpKcmFrqj2Cl_S&amp;_nc_ohc=MLD_QIj33hoAX-Um0DN&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfBcrwQOZ_r5SA-TgkF5ZJSDrh40eCQDIKOVLnWqEykf9w&amp;oe=65041AC2"
            title="Comunicado"
            subtitle="Comunicado a la opinión publica"
          ></gallery-card1>
          <gallery-card1
            rootClassName="rootClassName1"
            image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/217713090_461043168571496_8787260933231316593_n.jpg?_nc_cat=107&amp;ccb=1-7&amp;_nc_sid=5614bc&amp;_nc_eui2=AeFlhy6Ut3R6Jnb3ihU5PySkZSD2hiXpCi9lIPaGJekKL7KMBcsVF76WGoEBdTgPFnuRcokQQVcpKcmFrqj2Cl_S&amp;_nc_ohc=MLD_QIj33hoAX-Um0DN&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfBcrwQOZ_r5SA-TgkF5ZJSDrh40eCQDIKOVLnWqEykf9w&amp;oe=65041AC2"
            title="Comunicado"
            subtitle="Comunicado a la opinión publica"
          ></gallery-card1>
          <gallery-card1
            rootClassName="rootClassName"
            image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/217713090_461043168571496_8787260933231316593_n.jpg?_nc_cat=107&amp;ccb=1-7&amp;_nc_sid=5614bc&amp;_nc_eui2=AeFlhy6Ut3R6Jnb3ihU5PySkZSD2hiXpCi9lIPaGJekKL7KMBcsVF76WGoEBdTgPFnuRcokQQVcpKcmFrqj2Cl_S&amp;_nc_ohc=MLD_QIj33hoAX-Um0DN&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfBcrwQOZ_r5SA-TgkF5ZJSDrh40eCQDIKOVLnWqEykf9w&amp;oe=65041AC2"
            title="Comunicado"
            subtitle="Comunicado a la opinión publica"
          ></gallery-card1>
        </div>
      </div>
      <div class="normativa-container4">
        <h1 class="normativa-text5">
          <span>Resoluciones</span>
          <br />
        </h1>
        <div class="normativa-gallery4">
          <gallery-card1
            rootClassName="rootClassName3"
            image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t1.6435-9/105522168_1432855190239789_5715505841308478054_n.jpg?_nc_cat=110&amp;ccb=1-7&amp;_nc_sid=8bfeb9&amp;_nc_eui2=AeH89aUEIB0NG8pGeiuDDQrdnVdaaeTRfRqdV1pp5NF9GnkwkvTEEj_Ksm9KNfEUYYpKeJqrS_ZO5VV95wfdjl0R&amp;_nc_ohc=T0aiefyQqiMAX_yL3xf&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfC-ObGhIB_QPsIY2I8ANNLoqHyySiR3siaDz8lDX1Jm0w&amp;oe=6526A79A"
            title="Resolución xxxxx"
          ></gallery-card1>
        </div>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name4"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import GalleryCard1 from '../components/gallery-card1'
import AppFooter from '../components/footer'

export default {
  name: 'Normativa',
  components: {
    AppHeader,
    GalleryCard1,
    AppFooter,
  },
  data() {
    return {
      raw5nxq: ' ',
    }
  },
  metaInfo: {
    title: 'Normativa - ColegioTasco',
    meta: [
      {
        property: 'og:title',
        content: 'Normativa - ColegioTasco',
      },
    ],
  },
}
</script>

<style scoped>
.normativa-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.normativa-gallery {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-default);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.normativa-text {
  font-size: 3rem;
  text-align: center;
}
.normativa-text1 {
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.normativa-container1 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.normativa-gallery1 {
  width: 100%;
  display: grid;
  padding: var(--dl-space-space-threeunits);
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  grid-template-columns: 1fr;
}
.normativa-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.normativa-gallery2 {
  width: 100%;
  display: grid;
  padding: var(--dl-space-space-threeunits);
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  grid-template-columns: 1fr;
}
.normativa-container3 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.normativa-gallery3 {
  width: 100%;
  display: grid;
  padding: var(--dl-space-space-threeunits);
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  grid-template-columns: 1fr 1fr 1fr;
}
.normativa-container4 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.normativa-gallery4 {
  width: 100%;
  display: grid;
  padding: var(--dl-space-space-threeunits);
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  grid-template-columns: 1fr 1fr 1fr;
}
@media(max-width: 1366px) {
  .normativa-text {
    font-size: 3rem;
  }
  .normativa-text1 {
    color: var(--dl-color-gray-700);
  }
  .normativa-container1 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .normativa-text2 {
    text-align: center;
  }
  .normativa-container2 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .normativa-text3 {
    text-align: center;
  }
  .normativa-container3 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .normativa-text4 {
    text-align: center;
  }
  .normativa-container4 {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .normativa-text5 {
    text-align: center;
  }
}
@media(max-width: 991px) {
  .normativa-text1 {
    text-align: center;
  }
  .normativa-gallery1 {
    grid-template-columns: 1fr 1fr;
  }
  .normativa-gallery3 {
    grid-template-columns: 1fr 1fr;
  }
  .normativa-gallery4 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .normativa-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .normativa-text1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .normativa-gallery1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .normativa-gallery2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .normativa-gallery3 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .normativa-gallery4 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .normativa-gallery {
    padding: var(--dl-space-space-unit);
  }
  .normativa-gallery1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .normativa-gallery2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .normativa-gallery3 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .normativa-gallery4 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
</style>
