<template>
  <header data-role="Header" class="header-header" v-bind:class="rootClassName">
    <div class="header-social-bar">
      <div class="header-container">
        <span class="header-text">
          <span class="header-text1">
            informacion@institucioneducativajgmm.edu.co
          </span>
          <br />
        </span>
        <span class="header-text3">
          <span> +57 311 234 12 23</span>
          <br />
        </span>
      </div>
      <div class="header-container1">
        <svg viewBox="0 0 877.7142857142857 1024" class="header-icon">
          <path
            d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
          ></path></svg
        ><svg viewBox="0 0 602.2582857142856 1024" class="header-icon02">
          <path
            d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
          ></path></svg
        ><svg viewBox="0 0 1024 1024" class="header-icon04">
          <path
            d="M1013.8 307.2c0 0-10-70.6-40.8-101.6-39-40.8-82.6-41-102.6-43.4-143.2-10.4-358.2-10.4-358.2-10.4h-0.4c0 0-215 0-358.2 10.4-20 2.4-63.6 2.6-102.6 43.4-30.8 31-40.6 101.6-40.6 101.6s-10.2 82.8-10.2 165.8v77.6c0 82.8 10.2 165.8 10.2 165.8s10 70.6 40.6 101.6c39 40.8 90.2 39.4 113 43.8 82 7.8 348.2 10.2 348.2 10.2s215.2-0.4 358.4-10.6c20-2.4 63.6-2.6 102.6-43.4 30.8-31 40.8-101.6 40.8-101.6s10.2-82.8 10.2-165.8v-77.6c-0.2-82.8-10.4-165.8-10.4-165.8zM406.2 644.8v-287.8l276.6 144.4-276.6 143.4z"
          ></path>
        </svg>
      </div>
    </div>
    <div class="header-container2">
      <div class="header-container3">
        <img alt="iejgmm" src="/escudo1-200w.png" class="header-image" />
        <div class="header-nav">
          <link-menu rootClassName="rootClassName10"></link-menu>
        </div>
      </div>
      <div class="header-btn-group">
        <button type="button" class="header-button button">{{ button }}</button>
      </div>
      <div data-role="BurgerMenu" class="header-burger-menu">
        <svg viewBox="0 0 1024 1024" class="header-icon06">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
          ></path>
        </svg>
      </div>
      <div data-role="MobileMenu" class="header-mobile-menu">
        <div class="header-nav1">
          <div class="header-container4">
            <img :alt="image_alt1" :src="image_src1" class="header-image1" />
            <div data-role="CloseMobileMenu" class="header-menu-close">
              <svg viewBox="0 0 1024 1024" class="header-icon08">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                ></path>
              </svg>
            </div>
          </div>
          <link-menu rootClassName="rootClassName11"></link-menu>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LinkMenu from './link-menu'

export default {
  name: 'Header',
  props: {
    rootClassName: String,
    image_alt2: {
      type: String,
      default: 'image',
    },
    image_src2: {
      type: String,
      default: '28b6f2fe-b907-4f9b-88aa-75b8a54e099d',
    },
    image_alt1: {
      type: String,
      default: 'Ingresar',
    },
    image_src: {
      type: String,
      default:
        'https://play.teleporthq.io/static/svg/placeholders/no-image.svg',
    },
    image_src1: {
      type: String,
      default:
        'https://presentation-website-assets.teleporthq.io/logos/logo.png',
    },
    button: {
      type: String,
      default: 'Ingresar',
    },
    Register: String,
    image_alt: {
      type: String,
      default: 'image',
    },
  },
  components: {
    LinkMenu,
  },
}
</script>

<style scoped>
.header-header {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 100%;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-main-primary);
}
.header-social-bar {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  justify-content: space-between;
}
.header-container {
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.header-text {
  color: var(--dl-color-text-white);
}
.header-text3 {
  color: var(--dl-color-text-white);
  margin-top: var(--dl-space-space-halfunit);
}
.header-container1 {
  flex: 0 0 auto;
  width: auto;
  height: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.header-icon {
  fill: var(--dl-color-text-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-icon02 {
  fill: var(--dl-color-text-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-icon04 {
  fill: var(--dl-color-text-white);
  color: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.header-image {
  width: auto;
  height: var(--dl-size-size-large);
  object-fit: fill;
}
.header-nav {
  display: flex;
  position: relative;
  align-self: center;
  margin-left: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.header-btn-group {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header-button {
  border-width: 0px;
}
.header-burger-menu {
  display: none;
}
.header-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.header-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-container4 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header-image1 {
  height: 2rem;
}
.header-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-root-class-name {
  max-width: 100%;
}



.header-root-class-name4 {
  max-width: 100%;
}






@media(max-width: 1440px) {
  .header-header {
    height: auto;
  }
  .header-social-bar {
    height: auto;
  }
  .header-container2 {
    height: auto;
  }
}
@media(max-width: 1366px) {
  .header-image {
    width: auto;
    height: 96px;
  }
}
@media(max-width: 991px) {
  .header-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .header-social-bar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .header-text {
    font-size: 10px;
  }
  .header-text1 {
    font-size: 10px;
  }
  .header-text3 {
    font-size: 10px;
  }
  .header-nav {
    display: none;
  }
  .header-btn-group {
    display: none;
  }
  .header-burger-menu {
    color: var(--dl-color-gray-white);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-icon06 {
    width: 96px;
    height: var(--dl-size-size-small);
  }
  .header-mobile-menu {
    display: none;
    padding: 16px;
    background-color: var(--dl-color-main-primary);
  }
}
@media(max-width: 393px) {
  .header-social-bar {
    padding-left: 0px;
    padding-right: 0px;
  }
  .header-text {
    font-size: 10px;
  }
  .header-text1 {
    font-size: 10px;
  }
  .header-text3 {
    font-size: 10px;
  }
}
</style>
