<template>
  <div class="modalidad-feature-card" v-bind:class="rootClassName">
    <div class="modalidad-container">
      <img :alt="image_alt" :src="image_src" class="modalidad-image" />
    </div>
    <h2 class="modalidad-text">{{ title }}</h2>
    <span class="modalidad-text1">{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: 'Modalidad',
  props: {
    description: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.',
    },
    image_src1: {
      type: String,
      default: '5ad5d2d7-ddf3-4efe-aa10-d8d377df12c4',
    },
    title: {
      type: String,
      default: 'Lorem ipsum',
    },
    image_alt1: {
      type: String,
      default: 'image',
    },
    image_alt: {
      type: String,
      default: 'image_alt',
    },
    rootClassName: String,
    image_src: {
      type: String,
      default: 'https://play.teleporthq.io/static/svg/default-img.svg',
    },
  },
}
</script>

<style scoped>
.modalidad-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.modalidad-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.modalidad-image {
  width: 200px;
  object-fit: cover;
}
.modalidad-text {
  margin-top: var(--dl-space-space-unit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.modalidad-text1 {
  color: var(--dl-color-gray-700);
}
.modalidad-root-class-name {
  width: 100%;
}






@media(max-width: 720px) {
  .modalidad-feature-card {
    align-items: center;
  }
  .modalidad-image {
    align-self: center;
  }
  .modalidad-text {
    text-align: center;
  }
  .modalidad-text1 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .modalidad-text {
    font-style: normal;
    font-weight: 600;
  }
}
</style>
