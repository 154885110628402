<template>
  <div class="derecha-derecha">
    <div class="derecha-step"></div>
    <div class="derecha-step1">
      <div class="derecha-container">
        <div class="derecha-container1">
          <svg viewBox="0 0 1024 1024" class="derecha-icon">
            <path
              d="M634 558q92-64 92-174 0-88-63-151t-151-63-151 63-63 151q0 46 27 96t65 78l36 26v98h172v-98zM512 86q124 0 211 87t87 211q0 156-128 244v98q0 18-12 30t-30 12h-256q-18 0-30-12t-12-30v-98q-128-88-128-244 0-124 87-211t211-87zM384 896v-42h256v42q0 18-12 30t-30 12h-172q-18 0-30-12t-12-30z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="derecha-step2">
      <div class="derecha-container2">
        <h1 class="derecha-text">{{ heading }}</h1>
        <span class="derecha-text1">{{ desc }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Derecha',
  props: {
    heading: {
      type: String,
      default: 'Fundación',
    },
    desc: {
      type: String,
      default: 'Descripcion',
    },
  },
}
</script>

<style scoped>
.derecha-derecha {
  width: 100%;
  display: grid;
  position: relative;
  align-items: center;
  flex-direction: row;
  grid-template-areas: "a a a b c c c";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.derecha-step {
  display: flex;
  grid-area: a;
  align-items: center;
  flex-direction: column;
}
.derecha-step1 {
  display: flex;
  grid-area: b;
  align-items: center;
  flex-direction: column;
}
.derecha-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.derecha-container1 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.derecha-icon {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.derecha-step2 {
  display: flex;
  grid-area: c;
  align-items: flex-start;
  flex-direction: column;
}
.derecha-container2 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.derecha-text {
  font-size: 1.5rem;
  align-self: flex-start;
  text-align: left;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.derecha-text1 {
  font-size: 0.75rem;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 767px) {
  .derecha-derecha {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .derecha-step {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .derecha-step1 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .derecha-container {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .derecha-step2 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .derecha-container2 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .derecha-text {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .derecha-text1 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .derecha-container2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .derecha-text {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .derecha-text1 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 393px) {
  .derecha-derecha {
    grid-template-areas: "b c c c c c c";
  }
}
</style>
