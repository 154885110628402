<template>
  <div class="nosotros-container">
    <app-header rootClassName="header-root-class-name3"></app-header>
    <div class="nosotros-history">
      <h1 class="nosotros-text">Nuestra Historia</h1>
      <span class="nosotros-text01">
        <span>
          &quot;Más de 50 años en los cuales la Institución ha visto crecer, soñar
          y trasegar en el dificil camino del conocimiento a miles de niños y
          niñas que han pasdo por sus aulas deseosos de fortalecerse como seres
          humanos integrales, a innumerables docentes quienes han dejado sus
          vidas, amor y conocimiento a este claustro&quot;
        </span>
        <br />
      </span>
      <div class="nosotros-container01">
        <app-derecha
          desc="En 1965 Se crea el Colegio Departamental de Bachillerado de Tasco, Boyacá"
        ></app-derecha>
        <app-izquierda
            heading="Primera Planta Fisica"
          desc="15 de Agosto de 1969 Se inganura la primera etapa de la planta fisica"
        ></app-izquierda>
        <app-derecha
            heading="1976"
          desc="Se obtiene la primera graduacion de bachilleres"
        ></app-derecha>
        <app-izquierda
            heading="1978"
          desc="Se adopta modalidad de comercio"
        ></app-izquierda>
        <app-derecha
            heading="2008"
          desc="Integracion con el SENA"
        ></app-derecha>
      </div>
    </div>
    <section class="nosotros-identidad">
      <div class="nosotros-features">
        <h1 class="nosotros-heading">
          <span>Nuestra Identidad</span>
          <br />
        </h1>
        <div class="nosotros-container02">
          <mision-vision
            title="Misión"
            description=" Formar personas íntegras, ofreciendo niveles de Preescolar, Primaria, Secundaria y Media Técnica con el fin de capacitar estudiantes con los valores necesarios para desempeñarse laboral y socialmente, a través de Convenios Interinstitucionales y programas especiales de inclusión para la población en condición de vulnerabilidad. Líder en lo académico, artístico y cultural; orientada a la formación de la dimensión humana de personas críticas, creativas, con valores humanos íntegros y con sentido democrático."
            rootClassName="rootClassName"
          ></mision-vision>
          <mision-vision
            title="Visión"
            description="Se proyecta hacia el fortalecimiento de las competencias laborales, sociales, éticas y cognitivas, que faciliten el desempeño eficaz del estudiante con gran sentido de pertenencia social e incluyente en su comunidad educativa y un nivel alto en Pruebas Externas que le permitan afrontar e involucrarse activamente en los retos que la sociedad, la ciencia y la tecnología le exijan."
            rootClassName="rootClassName3"
          ></mision-vision>
        </div>
      </div>
      <img
        alt="image"
        src="/external/bottom.svg"
        class="nosotros-bottom-wave-image"
      />
      <img alt="image" src="/external/waves-white.svg" class="nosotros-image" />
      <img alt="image" src="/external/top.svg" class="nosotros-top-wave-image" />
    </section>
    <section class="nosotros-contaier">
      <h1 class="nosotros-heading1">
        <span>Nuestros Símbolos</span>
        <br />
      </h1>
      <div class="nosotros-container03">
        <div class="nosotros-container04">
          <h1 class="nosotros-heading2">
            <span>Escudo</span>
            <br />
          </h1>
        </div>
        <div class="nosotros-container05">
          <div class="nosotros-container06"></div>
          <div class="nosotros-container07">
            <div class="nosotros-container08">
              <span class="nosotros-text10">
                <span>
                  El escudo de la Institución está enmarcado por un croquis que se
                  usa en la ciencia y tiene forma de corazón. En la parte superior
                  aparece un sol naciente emblemático que significa el máximo
                  linaje de las directrices de cada una de las modalidades que
                  ofrece la institución; el verde esperanza refleja el éxito del
                  ciudadano bachiller egresado; el blanco, pureza, paz y nobleza,
                  al tanto con las nuevas tecnologías del nuevo milenio, libro
                  abierto, cultura y deseo por formar personas emprendedoras en
                  búsqueda de la excelencia, sin dejar de lado las políticas de
                  inclusion como máximo realce de nuestra institución.
                </span>
                <br />
                <span> </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="nosotros-divider"></div>
      <div class="nosotros-container09">
        <div class="nosotros-container10">
          <h1 class="nosotros-heading3">
            <span>Bandera</span>
            <br />
          </h1>
        </div>
        <div class="nosotros-container11">
          <div class="nosotros-container12">
            <div class="nosotros-container13">
              <span class="nosotros-text16">
                Formada por dos franjas oblicuas: una de color blanco que
                simboliza pureza, paz, nobleza, de los niños y juventud. La otra
                de color verde que representa la riqueza del territorio tasqueño. 
              </span>
            </div>
          </div>
          <div class="nosotros-container14"></div>
        </div>
      </div>
      <div class="nosotros-divider1"></div>
      <div class="nosotros-container15">
        <div class="nosotros-container16">
          <h1 class="nosotros-heading4">
            <span>Himno</span>
            <br />
          </h1>
          <div class="nosotros-feature-card">
            <svg viewBox="0 0 1024 1024" class="nosotros-icon">
              <path
                d="M738 470h72q0 108-75 189t-181 97v140h-84v-140q-106-16-181-97t-75-189h72q0 94 67 155t159 61 159-61 67-155zM460 210v264q0 20 15 35t37 15q20 0 35-14t15-36l2-264q0-22-16-37t-36-15-36 15-16 37zM512 598q-52 0-90-38t-38-90v-256q0-52 38-90t90-38 90 38 38 90v256q0 52-38 90t-90 38z"
              ></path>
            </svg>
            <div class="nosotros-container17">
              <span class="nosotros-text19">
                <span class="nosotros-text20">Coro:</span>
                <br />
                <br />
                <span>Entonad con orgullo y amor,</span>
                <br />
                <span>Estudiantes, el bello clamor.</span>
                <br />
                <span>Son las notas que brotan del alma</span>
                <br />
                <span>Inspiradas por nuestro Creador.</span>
              </span>
            </div>
          </div>
        </div>
        <div class="nosotros-container18">
          <div class="nosotros-features1">
            <div class="nosotros-container19">
              <div class="nosotros-feature-card1">
                <svg viewBox="0 0 1024 1024" class="nosotros-icon02">
                  <path
                    d="M896 726v-598h-598v598h598zM896 42q34 0 60 26t26 60v598q0 34-26 59t-60 25h-598q-34 0-59-25t-25-59v-598q0-34 25-60t59-26h598zM598 640v-342h-86v-84h170v426h-84zM128 214v682h682v86h-682q-34 0-60-26t-26-60v-682h86z"
                  ></path>
                </svg>
                <div class="nosotros-container20">
                  <span class="nosotros-text30">
                    <span>La persona, el saber y la ciencia</span>
                    <br />
                    <span>Anhelando el gran ideal</span>
                    <br />
                    <span>Formadores buscando excelencia</span>
                    <br />
                    <span>Nuestro lema se hará resaltar.</span>
                  </span>
                </div>
              </div>
              <div class="nosotros-feature-card2">
                <svg viewBox="0 0 1024 1024" class="nosotros-icon04">
                  <path
                    d="M726 554q0 36-25 61t-61 25h-170v-86h170v-84h-86v-86h86v-86h-170v-84h170q36 0 61 24t25 60v64q0 26-19 45t-45 19q26 0 45 19t19 45v64zM128 214v682h682v86h-682q-34 0-60-26t-26-60v-682h86zM896 726v-598h-598v598h598zM896 42q34 0 60 26t26 60v598q0 34-26 59t-60 25h-598q-34 0-59-25t-25-59v-598q0-34 25-60t59-26h598z"
                  ></path>
                </svg>
                <div class="nosotros-container21">
                  <span class="nosotros-text38">
                    <span>En memoria del gran Fundador</span>
                    <br />
                    <span>Nuestro claustro su nombre llevó.</span>
                    <br />
                    <span>Sentimientos y sueños forjemos</span>
                    <br />
                    <span>Y crezcamos como hombres de honor.</span>
                  </span>
                </div>
              </div>
              <div class="nosotros-feature-card3">
                <svg viewBox="0 0 1024 1024" class="nosotros-icon06">
                  <path
                    d="M726 554v86h-256v-170q0-36 24-61t60-25h86v-86h-170v-84h170q36 0 61 24t25 60v86q0 36-25 61t-61 25h-86v84h172zM896 726v-598h-598v598h598zM896 42q34 0 60 26t26 60v598q0 34-26 59t-60 25h-598q-34 0-59-25t-25-59v-598q0-34 25-60t59-26h598zM128 214v682h682v86h-682q-34 0-60-26t-26-60v-682h86z"
                  ></path>
                </svg>
                <div class="nosotros-container22">
                  <span class="nosotros-text46">
                    <span>Construcciones, Empresa y Diseno:</span>
                    <br />
                    <span>Tres opciones para progresar;</span>
                    <br />
                    <span>Con el arte y las letras vibremos,</span>
                    <br />
                    <span>Y en deportes por siempre triunfar</span>
                  </span>
                </div>
              </div>
              <div class="nosotros-feature-card4">
                <svg viewBox="0 0 1024 1024" class="nosotros-icon08">
                  <path
                    d="M896 726v-598h-598v598h598zM896 42q34 0 60 26t26 60v598q0 34-26 59t-60 25h-598q-34 0-59-25t-25-59v-598q0-34 25-60t59-26h598zM640 640v-170h-170v-256h84v170h86v-170h86v426h-86zM128 214v682h682v86h-682q-34 0-60-26t-26-60v-682h86z"
                  ></path>
                </svg>
                <div class="nosotros-container23">
                  <span class="nosotros-text54">
                    <span>En la cima del Tahúr plantemos</span>
                    <br />
                    <span>Esa antorcha que debe brillar.</span>
                    <br />
                    <span>De mi Tasco, mi bello colegio;</span>
                    <br />
                    <span>Sembradores de fe y libertad.</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppDerecha from '../components/derecha'
import AppIzquierda from '../components/izquierda'
import MisionVision from '../components/mision-vision'
import AppFooter from '../components/footer'

export default {
  name: 'Nosotros',
  components: {
    AppHeader,
    AppDerecha,
    AppIzquierda,
    MisionVision,
    AppFooter,
  },
  metaInfo: {
    title: 'Nosotros - ColegioTasco',
    meta: [
      {
        property: 'og:title',
        content: 'Nosotros - ColegioTasco',
      },
    ],
  },
}
</script>

<style scoped>
.nosotros-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.nosotros-history {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.nosotros-text {
  color: var(--dl-color-gray-black);
  font-size: 3rem;
}
.nosotros-text01 {
  color: var(--dl-color-gray-700);
  font-size: 1.15rem;
  max-width: 80%;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.nosotros-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.nosotros-identidad {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgba(22, 87, 18, 0.9) 0.00%,rgba(0, 131, 0, 0.9) 100.00%);
}
.nosotros-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-default);
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: 120px;
}
.nosotros-heading {
  z-index: 100;
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-threeunits);
}
.nosotros-container02 {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.nosotros-bottom-wave-image {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -5px;
  position: absolute;
  object-fit: cover;
}
.nosotros-image {
  top: auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.nosotros-top-wave-image {
  top: -5px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.nosotros-contaier {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-default);
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 3rem;
  padding-right: 3rem;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.nosotros-heading1 {
  z-index: 100;
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-threeunits);
}
.nosotros-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 3rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.nosotros-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nosotros-heading2 {
  z-index: 100;
  font-size: 3rem;
  margin-top: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.nosotros-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nosotros-container06 {
  width: 300px;
  height: 300px;
  display: flex;
  transition: 0.3s;
  background-size: contain;
  background-image: url("/escudo1-300w.png");
  background-repeat: no-repeat;
  background-position: center;
}
.nosotros-container06:hover {
  transform: scale(1.04);
}
.nosotros-container07 {
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.nosotros-container08 {
  flex: 0 0 auto;
  display: flex;
  padding: 1rem;
  max-width: 700px;
  align-items: flex-start;
  flex-direction: column;
}
.nosotros-text10 {
  font-size: 1.4rem;
  text-align: justify;
}
.nosotros-divider {
  flex: 0 0 auto;
  width: 100%;
  height: 3px;
  display: flex;
  opacity: 0.25;
  margin-top: 4rem;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: 4rem;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.nosotros-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 3rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.nosotros-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.nosotros-heading3 {
  z-index: 100;
  font-size: 3rem;
  margin-top: 0px;
  margin-right: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.nosotros-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nosotros-container12 {
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.nosotros-container13 {
  flex: 0 0 auto;
  display: flex;
  padding: 1rem;
  max-width: 700px;
  align-items: flex-start;
  flex-direction: column;
}
.nosotros-text16 {
  font-size: 1.4rem;
  text-align: justify;
}
.nosotros-container14 {
  width: 300px;
  height: 300px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  margin-left: var(--dl-space-space-oneandhalfunits);
  background-size: contain;
  justify-content: center;
  background-image: url("/bandera-300h.jpg");
  background-repeat: no-repeat;
  background-position: center;
}
.nosotros-container14:hover {
  transform: scale(1.04);
}
.nosotros-divider1 {
  flex: 0 0 auto;
  width: 100%;
  height: 3px;
  display: flex;
  opacity: 0.25;
  margin-top: 4rem;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: 4rem;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.nosotros-container15 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.nosotros-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.nosotros-heading4 {
  z-index: 100;
  font-size: 3rem;
  align-self: flex-start;
  margin-top: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.nosotros-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.nosotros-icon {
  fill: var(--dl-color-main-primary);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
}
.nosotros-container17 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.nosotros-text19 {
  font-size: 1.5rem;
}
.nosotros-text20 {
  font-style: normal;
  font-weight: 700;
}
.nosotros-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nosotros-features1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.nosotros-container19 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.nosotros-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.nosotros-icon02 {
  fill: var(--dl-color-main-primary);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
}
.nosotros-container20 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.nosotros-text30 {
  font-size: 1.5rem;
}
.nosotros-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.nosotros-icon04 {
  fill: var(--dl-color-main-primary);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
}
.nosotros-container21 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.nosotros-text38 {
  font-size: 1.5rem;
}
.nosotros-feature-card3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.nosotros-icon06 {
  fill: var(--dl-color-main-primary);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
}
.nosotros-container22 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.nosotros-text46 {
  font-size: 1.5rem;
}
.nosotros-feature-card4 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.nosotros-icon08 {
  fill: var(--dl-color-main-primary);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
}
.nosotros-container23 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.nosotros-text54 {
  font-size: 1.5rem;
}
@media(max-width: 1440px) {
  .nosotros-heading {
    margin-top: -16px;
  }
  .nosotros-heading1 {
    margin-top: -16px;
  }
  .nosotros-heading2 {
    margin-top: 0px;
  }
  .nosotros-heading3 {
    margin-top: 0px;
    margin-right: var(--dl-space-space-threeunits);
  }
  .nosotros-heading4 {
    margin-top: 0px;
  }
}
@media(max-width: 1366px) {
  .nosotros-heading {
    margin-top: -16px;
  }
  .nosotros-heading1 {
    margin-top: -16px;
  }
  .nosotros-heading2 {
    margin-top: 0px;
  }
  .nosotros-heading3 {
    margin-top: 0px;
    margin-right: var(--dl-space-space-threeunits);
  }
  .nosotros-heading4 {
    margin-top: 0px;
  }
}
@media(max-width: 991px) {
  .nosotros-text {
    text-align: center;
  }
  .nosotros-text01 {
    text-align: center;
  }
  .nosotros-heading {
    margin-top: -48px;
  }
  .nosotros-contaier {
    padding-top: var(--dl-space-space-unit);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: var(--dl-space-space-unit);
  }
  .nosotros-heading1 {
    margin-top: 0px;
  }
  .nosotros-container03 {
    margin-top: 0rem;
  }
  .nosotros-heading2 {
    margin-top: 0px;
  }
  .nosotros-container07 {
    width: 60%;
    margin-left: var(--dl-space-space-unit);
  }
  .nosotros-container08 {
    max-width: 800px;
  }
  .nosotros-container09 {
    margin-top: 0rem;
  }
  .nosotros-heading3 {
    margin-top: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .nosotros-container12 {
    width: 60%;
    margin-left: var(--dl-space-space-unit);
  }
  .nosotros-container13 {
    max-width: 800px;
  }
  .nosotros-container15 {
    margin-top: 0rem;
  }
  .nosotros-heading4 {
    margin-top: 0px;
  }
  .nosotros-features1 {
    padding: var(--dl-space-space-unit);
  }
  .nosotros-icon02 {
    margin-right: var(--dl-space-space-unit);
  }
  .nosotros-icon04 {
    margin-right: var(--dl-space-space-unit);
  }
  .nosotros-icon06 {
    margin-right: var(--dl-space-space-unit);
  }
  .nosotros-icon08 {
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .nosotros-history {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .nosotros-container01 {
    align-items: flex-start;
    padding-left: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .nosotros-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .nosotros-container02 {
    grid-template-columns: 1fr;
  }
  .nosotros-container03 {
    flex-direction: column;
  }
  .nosotros-container07 {
    width: 80%;
    margin-top: var(--dl-space-space-fourunits);
    align-items: center;
    margin-left: 0px;
  }
  .nosotros-divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .nosotros-container09 {
    flex-direction: column;
  }
  .nosotros-container12 {
    width: 80%;
    margin-top: var(--dl-space-space-fourunits);
    align-items: center;
    margin-left: 0px;
  }
  .nosotros-divider1 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .nosotros-container15 {
    flex-direction: column;
  }
  .nosotros-feature-card {
    flex-direction: row;
  }
  .nosotros-container17 {
    flex-direction: column;
  }
  .nosotros-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .nosotros-container19 {
    grid-template-columns: 1fr;
  }
  .nosotros-feature-card1 {
    flex-direction: row;
  }
  .nosotros-container20 {
    flex-direction: column;
  }
  .nosotros-feature-card2 {
    flex-direction: row;
  }
  .nosotros-container21 {
    flex-direction: column;
  }
  .nosotros-feature-card3 {
    flex-direction: row;
  }
  .nosotros-container22 {
    flex-direction: column;
  }
  .nosotros-feature-card4 {
    flex-direction: row;
  }
  .nosotros-container23 {
    flex-direction: column;
  }
}
@media(max-width: 720px) {
  .nosotros-heading {
    color: var(--dl-color-text-white);
    margin-top: var(--dl-space-space-threeunits);
  }
  .nosotros-container02 {
    grid-template-columns: 1fr;
  }
  .nosotros-heading1 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .nosotros-container03 {
    margin-top: var(--dl-space-space-unit);
  }
  .nosotros-heading2 {
    margin-top: 0px;
  }
  .nosotros-container05 {
    flex-direction: column;
  }
  .nosotros-container07 {
    width: 100%;
  }
  .nosotros-container08 {
    padding: var(--dl-space-space-twounits);
  }
  .nosotros-container09 {
    margin-top: var(--dl-space-space-unit);
  }
  .nosotros-container10 {
    justify-content: flex-start;
  }
  .nosotros-heading3 {
    margin-top: 0px;
  }
  .nosotros-container11 {
    flex-direction: column;
  }
  .nosotros-container12 {
    width: 100%;
  }
  .nosotros-container13 {
    padding: var(--dl-space-space-twounits);
  }
  .nosotros-container15 {
    margin-top: var(--dl-space-space-unit);
  }
  .nosotros-heading4 {
    margin-top: 0px;
  }
  .nosotros-container18 {
    flex-direction: column;
  }
  .nosotros-container19 {
    grid-template-columns: 1fr;
  }
}
@media(max-width: 479px) {
  .nosotros-history {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .nosotros-container01 {
    padding-left: 0px;
  }
  .nosotros-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .nosotros-container17 {
    flex-direction: column;
  }
  .nosotros-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .nosotros-container20 {
    flex-direction: column;
  }
  .nosotros-container21 {
    flex-direction: column;
  }
  .nosotros-container22 {
    flex-direction: column;
  }
  .nosotros-container23 {
    flex-direction: column;
  }
}
@media(max-width: 393px) {
  .nosotros-heading {
    color: var(--dl-color-text-white);
  }
  .nosotros-container02 {
    height: auto;
  }
  .nosotros-contaier {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .nosotros-heading2 {
    margin-bottom: 0px;
  }
  .nosotros-container05 {
    flex-direction: column;
  }
  .nosotros-container06 {
    width: 300px;
    height: 300px;
    margin: var(--dl-space-space-unit);
    padding: 0px;
  }
  .nosotros-container07 {
    margin: 0px;
  }
  .nosotros-container08 {
    padding: 0px;
  }
  .nosotros-container10 {
    align-self: flex-start;
    justify-content: flex-start;
  }
  .nosotros-heading3 {
    align-self: center;
    text-align: left;
    margin-right: var(--dl-space-space-unit);
    margin-bottom: 0px;
  }
  .nosotros-container11 {
    flex-direction: column;
  }
  .nosotros-container12 {
    margin: 0px;
  }
  .nosotros-container13 {
    padding: 0px;
  }
  .nosotros-container14 {
    width: 300px;
    height: 300px;
    margin: var(--dl-space-space-unit);
    padding: 0px;
  }
  .nosotros-heading4 {
    margin-bottom: 0px;
  }
  .nosotros-container18 {
    flex-direction: column;
  }
}
</style>
