<template>
  <div class="descubre-feature-card" v-bind:class="rootClassName">
    <h2 class="descubre-text">{{ title }}</h2>
    <img :alt="image_alt" :src="image_src" class="descubre-image" />
  </div>
</template>

<script>
export default {
  name: 'Descubre',
  props: {
    image_src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1512295767273-ac109ac3acfa?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHllbGxvdyUyMHRlY2h8ZW58MHx8fHwxNjI2MjU1NDk0&ixlib=rb-1.2.1&w=1000',
    },
    rootClassName: String,
    title: {
      type: String,
      default: 'Lorem ipsum',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
  },
}
</script>

<style scoped>
.descubre-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.descubre-feature-card:hover {
  transform: scale(1.02);
}
.descubre-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.descubre-image {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
















</style>
