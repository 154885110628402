<template>
  <div class="galeria-container">
    <app-header rootClassName="header-root-class-name8"></app-header>
    <div class="galeria-gallery">
      <h1 class="galeria-text">Nuestra Galería</h1>
      <span class="galeria-text1">
        En el corazón de esta galería, te invitamos a sumergirte en un viaje único
        a través del tiempo, donde los recuerdos se transforman en emociones y las
        imágenes se convierten en ventanas a momentos pasados
      </span>
      <div class="galeria-container1">
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/318790895_815901986418944_7187266130760659691_n.jpg?_nc_cat=110&amp;ccb=1-7&amp;_nc_sid=5614bc&amp;_nc_eui2=AeFl9Yi-1YmxqxSdH51ROD8bZi5pnTzReD9mLmmdPNF4P9hnHb-R40E-PC9RDstfhumd9WorY0e7PXYQH75WZl7r&amp;_nc_ohc=h9tcOU1kDDkAX-sKuM4&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfCGOInoHpSZEEunEGfGiM6rBVfDHHdsYx7IcboNsWB-Eg&amp;oe=650367D2"
          rootClassName="rootClassName"
        ></gallery-card3>
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/301795780_743459126996564_7767551994697011613_n.jpg?_nc_cat=110&amp;ccb=1-7&amp;_nc_sid=49d041&amp;_nc_eui2=AeHEPiw1pwR7dEDRNpGMBpxSBbQxPhPh2mEFtDE-E-HaYWuGVIlcxBOZxFISUNA6S0F7VKuiD2LHtb-4PlI07kg5&amp;_nc_ohc=XXDufJEeylgAX-eDn32&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfAgyAEKd8cDl-lb3HcMUnZPw1dS_rgVaxCpNrTKHgBU6g&amp;oe=65036BC8"
          rootClassName="rootClassName1"
        ></gallery-card3>
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/305148907_743420597000417_8200208451962760497_n.jpg?_nc_cat=111&amp;ccb=1-7&amp;_nc_sid=49d041&amp;_nc_aid=0&amp;_nc_eui2=AeEhOwWSyT_3Vsv_86l3UBiJ_YrMjRR4CN39isyNFHgI3V_1I5nkeXKAElXxem0VKMZkOIyLAuFr0kJDoSGu0dBC&amp;_nc_ohc=TqxdbwWwAZEAX9TnjFp&amp;_nc_oc=AQmBNjQy57K9z8q8mSlm5w1q9hFaSl1vEUnKB3LZ3356XIMUbY9mz4_TTWtY6Jddh3k&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfBDnETxYRe1emuu08E6p85QZHyHaDL3Tfb-uiWjy_HvSg&amp;oe=6503985F"
          rootClassName="rootClassName3"
        ></gallery-card3>
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/279461152_652355796106898_7238321839046379192_n.jpg?_nc_cat=110&amp;ccb=1-7&amp;_nc_sid=49d041&amp;_nc_eui2=AeGtnjeplReWtgFEkv5_9O2FeqSYGGcK8Hp6pJgYZwrweuCO_Va4t_f7HYH3rTZIBx1XMyTD7N-p9CiTuZ7EzRVQ&amp;_nc_ohc=6vEnkEf0pG4AX_kVglb&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfBQRFWnODP-gp_k6VipIp0jm99EdF4MMX_yO_BJU5xysA&amp;oe=65046D06"
          rootClassName="rootClassName2"
        ></gallery-card3>
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/256273983_543289493680196_393143632753554449_n.jpg?_nc_cat=110&amp;ccb=1-7&amp;_nc_sid=5614bc&amp;_nc_eui2=AeEUmh_UFm6ooGesdhsBv2O8cVMOXbHlCtxxUw5dseUK3D8BBSLfp6SjQAqoRyIupaxFxbasxtTIYbcIPQqOEtvH&amp;_nc_ohc=xxmPrIvTS_0AX9I5nBe&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfD0Tb4A6iJ3ZARYd5PrW441meunEQyhov1PnXWtJ1i0BQ&amp;oe=650460CF"
          rootClassName="rootClassName4"
        ></gallery-card3>
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/252742296_535135031162309_8142296930575741388_n.jpg?_nc_cat=105&amp;ccb=1-7&amp;_nc_sid=49d041&amp;_nc_eui2=AeHGgnLyk37GBujePij6KDVVveRVbPaO2uC95FVs9o7a4CUv4SR9IEj-NLK0-_O8YT1Y9zlkZ68VT2pSDuDhLXTO&amp;_nc_ohc=d_GmgPavsjEAX97pvaP&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfBIlpogUQoLck8UZL-bmocrYXfJ11IzEuEc5Ru-lVQSXQ&amp;oe=65033056"
          rootClassName="rootClassName5"
        ></gallery-card3>
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t1.6435-9/58376805_1087347494790562_9004087748503535616_n.jpg?_nc_cat=109&amp;ccb=1-7&amp;_nc_sid=8bfeb9&amp;_nc_eui2=AeGu2RLWIxW8ycYidF5zRLdKQaNuxTA3AmhBo27FMDcCaNr8nDF7oUASRYFqrmevvZ7EvzJemDUbcZ_jUCu_XZzU&amp;_nc_ohc=44f6uKcANOgAX9IC2mf&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfDNdRC8GlFqD3HFHhTqpk9DeedzrvkxdP7qhLnutZbPtA&amp;oe=65267D6A"
          rootClassName="rootClassName6"
        ></gallery-card3>
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t1.6435-9/44826416_975101016015211_804068592785031168_n.jpg?_nc_cat=101&amp;ccb=1-7&amp;_nc_sid=8bfeb9&amp;_nc_eui2=AeGt8cqVdprbMzg53Rcn-w2W17qEyZfU-VTXuoTJl9T5VMZqyahxhokh0IRKCWA33GnVtdBytLuhfxwGCvA5Fc2M&amp;_nc_ohc=WGJoDIEpRAQAX9WkNfK&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfDe69SD0mlEOCB87QachOdcvJ4O22VP_Q7Ys_f5FFC53w&amp;oe=65268E3C"
          rootClassName="rootClassName7"
        ></gallery-card3>
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t1.6435-9/41834744_953556931502953_2199229203528810496_n.jpg?_nc_cat=111&amp;ccb=1-7&amp;_nc_sid=730e14&amp;_nc_eui2=AeFCt5UjciA_nx1dEdrUggAEpk65WlcqUVCmTrlaVypRUPS0VE7ohG00PxMGLiTac2vYDKLjuUaHLcAX_3VTEU9J&amp;_nc_ohc=uN6BtzQEmTUAX-niNph&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfAo-NbR9j3E-9SuPqoAmnYm5LTZclCzigC5HaKEEewFKg&amp;oe=652674C4"
          rootClassName="rootClassName8"
        ></gallery-card3>
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t31.18172-8/13433338_517486958443288_3753290884484532802_o.jpg?_nc_cat=100&amp;ccb=1-7&amp;_nc_sid=8bfeb9&amp;_nc_eui2=AeEa_aDkCuJ5y1AFh5IMVttY2Zbd0SVCd03Zlt3RJUJ3TV77ZN6VnRELpHhMtB8_sOCigrEXWwBZSibvFESK1Ar2&amp;_nc_ohc=kxqGbSATIkcAX_zQPzV&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfAPD6cAImj3qtGToDeKHRIiwdDNdzUX9xP6xIMzZFkzdw&amp;oe=65269107"
          rootClassName="rootClassName9"
        ></gallery-card3>
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t1.18169-9/18221646_679802115545104_6595673204918706747_n.jpg?_nc_cat=107&amp;ccb=1-7&amp;_nc_sid=8bfeb9&amp;_nc_eui2=AeF_n0fnDlf6xxvnKttUh0b70t_cydElslTS39zJ0SWyVHuvgQINiq2BqI_aNdcGM4MyrG7wyyFO27WzJo5dLNk0&amp;_nc_ohc=aMaiitnmmEMAX9-VX54&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfDX0HnUiRQ7JHdlWDxcI6jjD-C6yNk8Pq84hu02EV7ZGg&amp;oe=65267F62"
          rootClassName="rootClassName10"
        ></gallery-card3>
        <gallery-card3
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/251026782_535135164495629_9101943652675422603_n.jpg?_nc_cat=108&amp;ccb=1-7&amp;_nc_sid=49d041&amp;_nc_eui2=AeG_V8Mi9juliC66Wa3dSACJ35z0rS0yHiHfnPStLTIeITkh4IZ8C1woNy0Rg07duMWhn0o24Aj6KyrCK1PHw49C&amp;_nc_ohc=-NxWPssHZSgAX9G2zsH&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfBfB1Wg3TR14t0ZO22nJU5HqTOZ3j_vE9iUZOpCyDY4ag&amp;oe=6504AE94"
          rootClassName="rootClassName11"
        ></gallery-card3>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name2"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import GalleryCard3 from '../components/gallery-card3'
import AppFooter from '../components/footer'

export default {
  name: 'Galeria',
  components: {
    AppHeader,
    GalleryCard3,
    AppFooter,
  },
  metaInfo: {
    title: 'Galeria - ColegioTasco',
    meta: [
      {
        property: 'og:title',
        content: 'Galeria - ColegioTasco',
      },
    ],
  },
}
</script>

<style scoped>
.galeria-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.galeria-gallery {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-default);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.galeria-text {
  font-size: 3rem;
  text-align: center;
}
.galeria-text1 {
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.galeria-container1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media(max-width: 991px) {
  .galeria-text1 {
    text-align: center;
  }
  .galeria-container1 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .galeria-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .galeria-text1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .galeria-container1 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 720px) {
  .galeria-container1 {
    grid-template-columns: 1fr;
  }
}
@media(max-width: 479px) {
  .galeria-gallery {
    padding: var(--dl-space-space-unit);
  }
  .galeria-container1 {
    grid-template-columns: 1fr;
  }
}
</style>
