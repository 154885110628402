var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modalidades-container"},[_c('app-header',{attrs:{"rootClassName":"header-root-class-name6"}}),_c('h1',{staticClass:"modalidades-text"},[_vm._v("Areas Técnicas")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('h1',{staticClass:"modalidades-text41"},[_vm._v(" \"Una Educación Incluyente y de Oportunidades\" ")]),_c('app-footer',{attrs:{"rootClassName":"footer-root-class-name"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modalidades-section"},[_c('div',{staticClass:"modalidades-content"},[_c('div',{staticClass:"modalidades-header"},[_c('h2',{staticClass:"modalidades-heading"},[_vm._v(" Patronaje Industrial de Prendas de Vestir ")]),_c('p',{staticClass:"modalidades-capton"},[_vm._v(" El patronaje industrial de prendas de vestir es un proceso esencial en la industria de la moda y la confección que se encarga de crear patrones precisos y técnicos para la fabricación de ropa. Esta disciplina combina la creatividad y la precisión técnica para transformar diseños conceptuales en patrones que servirán como guía para cortar y coser las telas y materiales necesarios para confeccionar una prenda de vestir. ")])])]),_c('img',{staticClass:"modalidades-image",attrs:{"alt":"image","src":"/patronaje-1400w.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modalidades-testimonial"},[_c('div',{staticClass:"modalidades-container01"},[_c('h1',{staticClass:"modalidades-text01"},[_c('span',[_vm._v("Habilidades y Conocimientos")]),_c('br')]),_c('div',{staticClass:"modalidades-step"},[_c('span',{staticClass:"modalidades-text04"},[_vm._v("01")]),_c('div',{staticClass:"modalidades-container02"},[_c('span',{staticClass:"modalidades-text05"},[_vm._v("Diseño y Conceptualización")]),_c('span',{staticClass:"modalidades-text06"},[_vm._v(" El proceso de patronaje industrial comienza con la conceptualización de una prenda de vestir. Los diseñadores crean bocetos y conceptos de moda que luego se transforman en patrones técnicos. ")])])]),_c('div',{staticClass:"modalidades-step1"},[_c('span',{staticClass:"modalidades-text07"},[_vm._v("02")]),_c('div',{staticClass:"modalidades-container03"},[_c('span',{staticClass:"modalidades-text08"},[_vm._v("Construcción de Patrones")]),_c('span',{staticClass:"modalidades-text09"},[_vm._v(" Los patrones se crean utilizando técnicas matemáticas y geométricas. Esto implica trazar líneas, curvas y formas que representen cada pieza de tela que compondrá la prenda, como la parte delantera, trasera, mangas, cuellos, etc ")])])]),_c('div',{staticClass:"modalidades-step2"},[_c('span',{staticClass:"modalidades-text10"},[_vm._v("03")]),_c('div',{staticClass:"modalidades-container04"},[_c('span',{staticClass:"modalidades-text11"},[_vm._v("Confección")]),_c('span',{staticClass:"modalidades-text12"},[_vm._v(" Las piezas cortadas se ensamblan utilizando máquinas de coser y técnicas de costura específicas. Aquí es donde las piezas toman forma y se convierten en una prenda completa. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modalidades-section1"},[_c('div',{staticClass:"modalidades-content1"},[_c('div',{staticClass:"modalidades-header1"},[_c('h2',{staticClass:"modalidades-heading1"},[_vm._v(" Técnico Mecánico de Maquinaria Industrial ")]),_c('p',{staticClass:"modalidades-capton1"},[_vm._v(" Un técnico mecánico de maquinaria industrial es un profesional altamente capacitado que se especializa en el mantenimiento, reparación y operación de maquinaria industrial utilizada en diversas industrias, como la manufactura, la construcción, la minería, la energía, la automoción y muchas otras. Estos técnicos desempeñan un papel crucial para garantizar que las máquinas y equipos industriales funcionen de manera eficiente y segura. ")])])]),_c('img',{staticClass:"modalidades-image1",attrs:{"alt":"image","src":"/mecanica-1400w.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modalidades-testimonial1"},[_c('div',{staticClass:"modalidades-container05"},[_c('h1',{staticClass:"modalidades-text13"},[_c('span',[_vm._v("Habilidades y Conocimientos")]),_c('br')]),_c('div',{staticClass:"modalidades-step3"},[_c('span',{staticClass:"modalidades-text16"},[_vm._v("01")]),_c('div',{staticClass:"modalidades-container06"},[_c('span',{staticClass:"modalidades-text17"},[_vm._v("Mantenimiento Preventivo")]),_c('span',{staticClass:"modalidades-text18"},[_vm._v(" Realizan inspecciones regulares para identificar y abordar posibles problemas antes de que se conviertan en fallas graves. Lubrican, ajustan y reemplazan componentes según sea necesario. ")])])]),_c('div',{staticClass:"modalidades-step4"},[_c('span',{staticClass:"modalidades-text19"},[_vm._v("02")]),_c('div',{staticClass:"modalidades-container07"},[_c('span',{staticClass:"modalidades-text20"},[_vm._v("Lectura de Planos")]),_c('span',{staticClass:"modalidades-text21"},[_vm._v(" Pueden interpretar planos técnicos y diagramas de circuitos para comprender la estructura y el funcionamiento de la maquinaria. ")])])]),_c('div',{staticClass:"modalidades-step5"},[_c('span',{staticClass:"modalidades-text22"},[_vm._v("03")]),_c('div',{staticClass:"modalidades-container08"},[_c('span',{staticClass:"modalidades-text23"},[_vm._v("Resolución de Problemas")]),_c('span',{staticClass:"modalidades-text24"},[_vm._v(" Son capaces de diagnosticar problemas y desarrollar soluciones efectivas en situaciones de fallo o mal funcionamiento de la maquinaria. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modalidades-section2"},[_c('div',{staticClass:"modalidades-content2"},[_c('div',{staticClass:"modalidades-header2"},[_c('h2',{staticClass:"modalidades-heading2"},[_vm._v(" Técnico en Contabilización de Operaciones Comerciales y Financieras ")]),_c('p',{staticClass:"modalidades-capton2"},[_vm._v(" Un técnico en contabilización de operaciones comerciales y financieras es un profesional especializado en llevar a cabo tareas relacionadas con la contabilidad en el ámbito empresarial. Su trabajo consiste en registrar, analizar y mantener un control adecuado de las transacciones comerciales y financieras de una empresa. ")])])]),_c('img',{staticClass:"modalidades-image2",attrs:{"alt":"image","src":"/contabilizacion-1400w.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modalidades-testimonial2"},[_c('div',{staticClass:"modalidades-container09"},[_c('h1',{staticClass:"modalidades-text25"},[_c('span',[_vm._v("Habilidades y Conocimientos")]),_c('br')]),_c('div',{staticClass:"modalidades-step6"},[_c('span',{staticClass:"modalidades-text28"},[_vm._v("01")]),_c('div',{staticClass:"modalidades-container10"},[_c('span',{staticClass:"modalidades-text29"},[_vm._v("Conocimientos Contables")]),_c('span',{staticClass:"modalidades-text30"},[_vm._v(" Poseer un sólido conocimiento de los principios contables y las normas financieras aplicables. ")])])]),_c('div',{staticClass:"modalidades-step7"},[_c('span',{staticClass:"modalidades-text31"},[_vm._v("02")]),_c('div',{staticClass:"modalidades-container11"},[_c('span',{staticClass:"modalidades-text32"},[_vm._v("Habilidades Analíticas")]),_c('span',{staticClass:"modalidades-text33"},[_c('span',[_vm._v(" Ser capaz de analizar datos financieros y detectar tendencias o áreas de mejora. ")]),_c('br')])])]),_c('div',{staticClass:"modalidades-step8"},[_c('span',{staticClass:"modalidades-text36"},[_vm._v("03")]),_c('div',{staticClass:"modalidades-container12"},[_c('span',{staticClass:"modalidades-text37"},[_vm._v("Análisis de Costos")]),_c('span',{staticClass:"modalidades-text38"},[_c('span',[_vm._v(" Analizar los costos de producción y operativos para identificar oportunidades de ahorro y mejorar la eficiencia. ")]),_c('br')])])])])])
}]

export { render, staticRenderFns }