<template>
  <div class="docente-card" v-bind:class="rootClassName">
    <div class="docente-info">
      <span class="docente-text">{{ text }}</span>
      <span class="docente-text1">{{ text1 }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Docente',
  props: {
    text1: {
      type: String,
      default:
        'Lic. En Educación',
    },
    text: {
      type: String,
      default: 'Andrés Gil',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.docente-card {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: 20px;
  max-width: auto;
  min-height: 400px;
  align-items: center;
  border-radius: 36px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/external/rectangle%2035-1500w-1500w.png");
  background-position: center;
}
.docente-info {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-scheme-white);
}
.docente-text {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.docente-text1 {
  line-height: 1.5;
}

.docente-root-class-name1 {
  background-size: cover;
  background-image: url("/rectangle%2035%20%5B2%5D-200h.png");
}
.docente-root-class-name2 {
  background-size: cover;
  background-image: url("/rectangle%2035%20%5B1%5D-200h.png");
}
.docente-root-class-name3 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name4 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name5 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
.docente-root-class-name6 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name7 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
.docente-root-class-name8 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name9 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
.docente-root-class-name10 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name11 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
.docente-root-class-name12 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name13 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
.docente-root-class-name14 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name15 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
.docente-root-class-name16 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name17 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
.docente-root-class-name18 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name19 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
.docente-root-class-name20 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name21 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
.docente-root-class-name22 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name23 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
.docente-root-class-name24 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesor-200h.png");
}
.docente-root-class-name25 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
.docente-root-class-name26 {
  width: auto;
  max-width: auto;
  min-height: 400px;
  background-image: url("/profesora-200h.png");
}
@media(max-width: 767px) {
  .docente-card {
    width: 100%;
  }
}
</style>
