<template>
  <div class="izquierda-izquierda">
    <div class="izquierda-step">
      <div class="izquierda-container">
        <h1 class="izquierda-text">{{ heading }}</h1>
        <h1 class="izquierda-text1">{{ desc }}</h1>
      </div>
    </div>
    <div class="izquierda-step1">
      <div class="izquierda-container1">
        <div class="izquierda-container2">
          <svg viewBox="0 0 1024 1024" class="izquierda-icon">
            <path
              d="M746 512q26 0 45-18t19-46-19-46-45-18-45 18-19 46 19 46 45 18zM618 342q26 0 45-19t19-45-19-45-45-19-45 19-19 45 19 45 45 19zM406 342q26 0 45-19t19-45-19-45-45-19-45 19-19 45 19 45 45 19zM278 512q26 0 45-18t19-46-19-46-45-18-45 18-19 46 19 46 45 18zM512 128q158 0 271 100t113 242q0 88-63 150t-151 62h-74q-28 0-46 19t-18 45q0 22 16 42t16 44q0 28-18 46t-46 18q-160 0-272-112t-112-272 112-272 272-112z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="izquierda-step2"></div>
  </div>
</template>

<script>
export default {
  name: 'Izquierda',
  props: {
    desc: {
      type: String,
      default: 'Desc',
    },
    heading: {
      type: String,
      default: 'Ideate',
    },
  },
}
</script>

<style scoped>
.izquierda-izquierda {
  width: 100%;
  display: grid;
  position: relative;
  align-items: center;
  flex-direction: row;
  grid-template-areas: "a a a b c c c";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.izquierda-step {
  display: flex;
  grid-area: a;
  align-items: flex-end;
  flex-direction: column;
}
.izquierda-container {
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.izquierda-text {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.izquierda-text1 {
  font-size: 0.75rem;
  font-style: normal;
  text-align: right;
  font-weight: 400;
  margin-bottom: var(--dl-space-space-unit);
}
.izquierda-step1 {
  display: flex;
  grid-area: b;
  align-items: center;
  flex-direction: column;
}
.izquierda-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.izquierda-container2 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.izquierda-icon {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.izquierda-step2 {
  display: flex;
  grid-area: c;
  align-items: center;
  flex-direction: column;
}
@media(max-width: 767px) {
  .izquierda-izquierda {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .izquierda-step {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .izquierda-container {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .izquierda-text {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .izquierda-text1 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .izquierda-step1 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .izquierda-container1 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .izquierda-step2 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .izquierda-container {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .izquierda-text {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .izquierda-text1 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 393px) {
  .izquierda-izquierda {
    grid-template-areas: "b a a a a a a";
  }
  .izquierda-step {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .izquierda-container {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .izquierda-text1 {
    text-align: left;
  }
  .izquierda-step2 {
    display: none;
  }
}
</style>
