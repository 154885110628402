import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import NotFound from './views/not-found'
import Galeria from './views/galeria'
import Docentes from './views/docentes'
import Modalidades from './views/modalidades'
import Secciones from './views/secciones'
import Main from './views/main'
import Calendario from './views/calendario'
import Nosotros from './views/nosotros'
import Normativa from './views/normativa'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    // {
    //   name: '404 - Not Found',
    //   path: '**',
    //   component: NotFound,
    // },
    {
      name: 'Galeria',
      path: '/galeria',
      component: Galeria,
    },
    {
      name: 'Docentes',
      path: '/docentes',
      component: Docentes,
    },
    {
      name: 'Modalidades',
      path: '/modalidades',
      component: Modalidades,
    },
    {
      name: 'Secciones',
      path: '/secciones',
      component: Secciones,
    },
    {
      name: 'Main',
      path: '/',
      component: Main,
    },
    {
      name: 'Calendario',
      path: '/calendario',
      component: Calendario,
    },
    {
      name: 'Nosotros',
      path: '/nosotros',
      component: Nosotros,
    },
    {
      name: 'Normativa',
      path: '/normativa',
      component: Normativa,
    },
  ],
})
