<template>
  <div class="sedes-speaker-card" v-bind:class="rootClassName">
    <span class="sedes-first-name">{{ project_title2 }}</span>
    <div class="sedes-image-container">
      <div class="sedes-see-project-container">
        <button class="button-md button-secondary-white button">
          {{ Button }}
        </button>
      </div>
      <img :alt="image_alt" :src="image_src" class="sedes-image" />
    </div>
    <span class="sedes-first-name1">{{ project_title }}</span>
  </div>
</template>

<script>
export default {
  name: 'Sedes',
  props: {
    rootClassName: String,
    Button: {
      type: String,
      default: 'Ver detalle',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    project_title: {
      type: String,
      default: 'Sede',
    },
    image_src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEwfHxwZW9wbGV8ZW58MHx8fHwxNjQzNzA1NTEx&ixlib=rb-1.2.1&w=300',
    },
    project_title2: {
      type: String,
      default: 'Sede',
    },
  },
}
</script>

<style scoped>
.sedes-speaker-card {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.sedes-first-name {
  display: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}
.sedes-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  min-width: 300px;
  max-height: 313px;
  min-height: 313px;
  align-items: flex-end;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-end;
}
.sedes-see-project-container {
  flex: 1;
  width: 100%;
  cursor: pointer;
  display: flex;
  opacity: 0;
  z-index: 1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(.jpg);
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
}
.sedes-see-project-container:hover {
  opacity: 1;
}
.sedes-image {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  position: absolute;
  object-fit: fill;
  border-radius: var(--dl-radius-radius-radius8);
}
.sedes-first-name1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}











@media(max-width: 393px) {
  .sedes-first-name {
    display: flex;
  }
  .sedes-first-name1 {
    display: none;
  }
}
</style>
