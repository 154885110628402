<template>
  <footer class="footer-footer" v-bind:class="rootClassName">
    <div class="footer-container">
      <div class="footer-container01">
        <div class="footer-container02">
          <img alt="logo" src="/escudo1-1400w.png" class="footer-image" />
          <img :alt="image_alt" :src="image_src" class="footer-image1" />
        </div>
        <div class="footer-container03">
          <div class="footer-container04">
            <span class="footer-text">Celular : 310 767 6260</span>
            <span class="footer-text01">
              informacion@institucioneducativajgmm.edu.co
            </span>
            <span class="footer-text02">
              Lunes a viernes de 8:00 am a 11:45 m
            </span>
            <span class="footer-text03">01:15 pm a 4:00 pm</span>
            <span class="footer-text04">Cra 5 # 5 - 22, Tasco - Boyacá</span>
          </div>
        </div>
        <div class="footer-icon-group">
          <svg viewBox="0 0 950.8571428571428 1024" class="footer-icon">
            <path
              d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
            ></path></svg
          ><svg viewBox="0 0 877.7142857142857 1024" class="footer-icon2">
            <path
              d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
            ></path></svg
          ><svg viewBox="0 0 602.2582857142856 1024" class="footer-icon4">
            <path
              d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
            ></path>
          </svg>
        </div>
      </div>
      <div class="footer-links-container">
        <div class="footer-container05">
          <div class="footer-container06">
            <span class="footer-text05">Nosotros</span>
            <span class="footer-text06">Historia</span>
            <span class="footer-text07">Identidad</span>
            <span class="footer-text08">Simbolos</span>
          </div>
          <div class="footer-container07">
            <span class="footer-text09">
              <span>Normatividad</span>
              <br />
            </span>
            <span class="footer-text12">Circulares</span>
            <span class="footer-text13">Comunicados</span>
            <span class="footer-text14">PEI</span>
          </div>
        </div>
        <div class="footer-container08">
          <div class="footer-container09">
            <span class="footer-text15">Academia</span>
            <span class="footer-text16">Docentes</span>
            <span class="footer-text17">Areas Técnicas</span>
            <span class="footer-text18">Secciones</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    text211: {
      type: String,
      default: 'Pricing',
    },
    text24: {
      type: String,
      default: 'Pricing',
    },
    text82: {
      type: String,
      default: 'Contact',
    },
    image_src: {
      type: String,
      default: '/image-200h.png',
    },
    text20: {
      type: String,
      default: 'Product',
    },
    rootClassName: String,
    text62: {
      type: String,
      default: 'About',
    },
    text92: {
      type: String,
      default: 'Blog',
    },
    text7: {
      type: String,
      default: 'Careers',
    },
    text41: {
      type: String,
      default: 'Releases',
    },
    text32: {
      type: String,
      default: '01:15 pm a 4:00 pm',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    text4: {
      type: String,
      default: 'Releases',
    },
    text21: {
      type: String,
      default: 'Pricing',
    },
    text: {
      type: String,
      default: 'Product',
    },
    text23: {
      type: String,
      default: 'Lunes a viernes de 8:00 am a 11:45 m',
    },
    text8: {
      type: String,
      default: 'Contact',
    },
    text22: {
      type: String,
      default: 'Celular : 310 767 6260',
    },
    text31: {
      type: String,
      default: 'Tutorials',
    },
    text6: {
      type: String,
      default: 'About',
    },
    text3: {
      type: String,
      default: 'Tutorials',
    },
    text72: {
      type: String,
      default: 'Careers',
    },
    text9: {
      type: String,
      default: 'Blog',
    },
    text52: {
      type: String,
      default: 'Company',
    },
    text111: {
      type: String,
      default: 'informacion@institucioneducativajgmm.edu.co',
    },
    text5: {
      type: String,
      default: 'Company',
    },
    text2: {
      type: String,
      default: 'Pricing',
    },
    text42: {
      type: String,
      default: 'Cra 5 # 5 - 22, Tasco - Boyacá',
    },
  },
}
</script>

<style scoped>
.footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: var(--dl-color-main-primary);
}
.footer-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: var(--dl-size-size-default);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container01 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.footer-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
}
.footer-image {
  height: 96px;
  align-self: flex-start;
  margin-bottom: 0px;
}
.footer-image1 {
  width: auto;
  height: 96px;
  object-fit: cover;
}
.footer-container03 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container04 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text {
  color: var(--dl-color-text-white);
  font-style: normal;
  font-weight: 400;
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text01 {
  color: var(--dl-color-text-white);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text02 {
  color: var(--dl-color-text-white);
  margin-bottom: 0px;
}
.footer-text03 {
  color: var(--dl-color-text-white);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text04 {
  color: var(--dl-color-text-white);
}
.footer-icon-group {
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer-icon {
  fill: var(--dl-color-text-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.footer-icon2 {
  fill: var(--dl-color-text-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.footer-icon4 {
  fill: var(--dl-color-text-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.footer-links-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container05 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.footer-container06 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text05 {
  color: var(--dl-color-text-white);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-text06 {
  color: var(--dl-color-text-white);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text07 {
  color: var(--dl-color-text-white);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text08 {
  color: var(--dl-color-text-white);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-container07 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text09 {
  color: var(--dl-color-text-white);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-text12 {
  color: var(--dl-color-text-white);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text13 {
  color: var(--dl-color-text-white);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text14 {
  color: var(--dl-color-text-white);
}
.footer-container08 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer-container09 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text15 {
  color: var(--dl-color-text-white);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-text16 {
  color: var(--dl-color-text-white);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text17 {
  color: var(--dl-color-text-white);
  margin-bottom: var(--dl-space-space-unit);
}
.footer-text18 {
  color: var(--dl-color-text-white);
  margin-bottom: var(--dl-space-space-unit);
}





@media(max-width: 991px) {
  .footer-footer {
    flex-direction: column;
  }
  .footer-container {
    align-items: center;
  }
  .footer-container01 {
    align-self: flex-start;
    align-items: flex-start;
  }
  .footer-container03 {
    margin-right: 0px;
  }
  .footer-links-container {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    flex-direction: row;
  }
  .footer-container05 {
    margin-right: 0px;
  }
  .footer-container06 {
    margin-right: var(--dl-space-space-twounits);
  }
  .footer-container07 {
    margin-right: var(--dl-space-space-twounits);
  }
  .footer-container08 {
    margin-right: 0px;
  }
  .footer-container09 {
    margin-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 767px) {
  .footer-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-image {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-container03 {
    width: 100%;
    justify-content: space-between;
  }
  .footer-links-container {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-container05 {
    width: 100%;
    justify-content: space-between;
  }
  .footer-container07 {
    margin-right: 0px;
  }
  .footer-container08 {
    width: 100%;
    justify-content: space-between;
  }
}
@media(max-width: 720px) {
  .footer-container {
    align-self: flex-start;
    justify-content: space-between;
  }
  .footer-container01 {
    align-items: center;
    justify-content: center;
  }
  .footer-image {
    align-self: flex-start;
  }
  .footer-container08 {
    display: none;
  }
}
@media(max-width: 479px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
  }
  .footer-image {
    margin-bottom: 0px;
  }
  .footer-container03 {
    align-items: center;
    margin-right: 0px;
    justify-content: space-between;
  }
  .footer-container04 {
    margin-right: var(--dl-space-space-unit);
  }
  .footer-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-container05 {
    align-items: center;
    margin-right: 0px;
    justify-content: space-between;
  }
  .footer-container06 {
    margin-right: var(--dl-space-space-unit);
  }
  .footer-container07 {
    align-self: stretch;
  }
  .footer-container08 {
    align-items: center;
    margin-right: 0px;
    justify-content: space-between;
  }
  .footer-container09 {
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 393px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
  }
  .footer-container02 {
    align-items: center;
    justify-content: center;
  }
  .footer-image {
    align-self: center;
  }
  .footer-links-container {
    display: none;
  }
}
</style>
