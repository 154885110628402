<template>
  <div class="noticias-feature-card" v-bind:class="rootClassName">
    <img :alt="image_alt" :src="image_src" class="noticias-image" />
    <h2 class="noticias-text">{{ title }}</h2>
    <span class="noticias-text1">{{ description }}</span>
    <span class="noticias-text2">{{ action }}</span>
  </div>
</template>

<script>
export default {
  name: 'Noticias',
  props: {
    action: {
      type: String,
      default: 'Ver más',
    },
    image_src: {
      type: String,
      default: 'https://play.teleporthq.io/static/svg/default-img.svg',
    },
    description: {
      type: String,
      default: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
    },
    rootClassName: String,
    image_alt: {
      type: String,
      default: 'image',
    },
    title: {
      type: String,
      default: 'Lorem ipsum',
    },
  },
}
</script>

<style scoped>
.noticias-feature-card {
  width: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.noticias-image {
  width: auto;
  height: 200px;
  object-fit: fill;
}
.noticias-text {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.noticias-text1 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.noticias-text2 {
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
}
.noticias-root-class-name {
  width: auto;
}


.noticias-root-class-name3 {
  width: auto;
}

.noticias-root-class-name5 {
  width: auto;
}
</style>
