<template>
  <div class="main-container">
    <app-header rootClassName="header-root-class-name2"></app-header>
    <div class="main-hero">
      <h1 class="main-text">
        &quot;Formamos personas en busca de la excelencia&quot;
      </h1>
      <div class="main-btn-group"></div>
    </div>
    <div class="main-features">
      <h1 class="main-text01">
        <span>Ultimas Noticias</span>
        <br />
      </h1>
      <span class="main-text04">
        Enterate de los utlimos eventos de la institucion
      </span>
      <div class="main-container1">
        <app-noticias
          title="Comunicado"
          action="Ver más"
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/348664656_961390551870086_1532559047874000605_n.jpg?stp=dst-jpg_p180x540&amp;_nc_cat=109&amp;ccb=1-7&amp;_nc_sid=5614bc&amp;_nc_eui2=AeF-GXDBLNgcWLXyE37UflzydW9yH-VwYEh1b3If5XBgSLvslqbLQNbIeYT-el7r4Jfs9X2ssfamj7cX-FHJhf34&amp;_nc_ohc=NmzvMNxo2T8AX_WYYro&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfDiqRSgc7boLbO5zaVps4sEALeeFlFgwkA9MhTwDzypEw&amp;oe=650431E8"
          description="Entrega Informes 2° periodo"
          rootClassName="rootClassName"
        ></app-noticias>
        <app-noticias
          title="Noticia"
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/327293562_889288478775840_8205095199395888126_n.jpg?stp=dst-jpg_s960x960&amp;_nc_cat=109&amp;ccb=1-7&amp;_nc_sid=5614bc&amp;_nc_eui2=AeHKTR8vFMI7k6rD4HeAJvHE-qddO4h4wH36p107iHjAfSwvjdF5ef5BgihBWUBeN2sWnnek2lDuqeZ4qw5Qs2DZ&amp;_nc_ohc=_bXXZQ5RLTUAX-tulI9&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfBdTcfCw3h3rSngRrPhXe5kb_m0OTTHDq1HnRuyq7jafg&amp;oe=65049038"
          description="Información para Padres de Familia"
          rootClassName="rootClassName3"
        ></app-noticias>
        <app-noticias
          title="Circulares"
          image_src="https://scontent.fbga2-1.fna.fbcdn.net/v/t39.30808-6/341540143_1620269268454580_3828344707736344645_n.jpg?_nc_cat=103&amp;ccb=1-7&amp;_nc_sid=5614bc&amp;_nc_eui2=AeHOMFxRHPapoGRxFLFymbQf6Cm9rVyZBM_oKb2tXJkEzy_pDGgUdy2iVrnK8273laUAELIcFvmAB-oO-4EsXZtF&amp;_nc_ohc=cD9GEjsvPPwAX-mpXyL&amp;_nc_ht=scontent.fbga2-1.fna&amp;oh=00_AfCngftEkht3MccwJsoiP9clq3vJFxncBnA913jeX0etlg&amp;oe=6504CE4B"
          description="Entrega Informes 1er Periodo"
          rootClassName="rootClassName5"
        ></app-noticias>
      </div>
    </div>
    <div class="main-stats">
      <div class="main-stat">
        <svg viewBox="0 0 1152 1024" class="main-icon">
          <path
            d="M768 770.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"
          ></path>
          <path
            d="M327.196 795.328c55.31-36.15 124.080-63.636 199.788-80.414-15.054-17.784-28.708-37.622-40.492-59.020-30.414-55.234-46.492-116.058-46.492-175.894 0-86.042 0-167.31 30.6-233.762 29.706-64.504 83.128-104.496 159.222-119.488-16.914-76.48-61.94-126.75-181.822-126.75-192 0-192 128.942-192 288 0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h279.006c14.518-12.91 30.596-25.172 48.19-36.672z"
          ></path>
        </svg>
        <span class="main-text05">Estudiantes</span>
        <h1 class="main-text06">+500</h1>
      </div>
      <div class="main-stat1">
        <svg viewBox="0 0 1152 1024" class="main-icon03">
          <path
            d="M768 770.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"
          ></path>
          <path
            d="M327.196 795.328c55.31-36.15 124.080-63.636 199.788-80.414-15.054-17.784-28.708-37.622-40.492-59.020-30.414-55.234-46.492-116.058-46.492-175.894 0-86.042 0-167.31 30.6-233.762 29.706-64.504 83.128-104.496 159.222-119.488-16.914-76.48-61.94-126.75-181.822-126.75-192 0-192 128.942-192 288 0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h279.006c14.518-12.91 30.596-25.172 48.19-36.672z"
          ></path>
        </svg>
        <span class="main-text07">Egresados</span>
        <h1 class="main-text08">+3000</h1>
      </div>
      <div class="main-stat2">
        <svg viewBox="0 0 1024 1024" class="main-icon06">
          <path
            d="M810 640v-86h-84v86h84zM810 810v-84h-84v84h84zM554 298v-84h-84v84h84zM554 470v-86h-84v86h84zM554 640v-86h-84v86h84zM554 810v-84h-84v84h84zM298 470v-86h-84v86h84zM298 640v-86h-84v86h84zM298 810v-84h-84v84h84zM640 470h256v426h-768v-598h256v-84l128-128 128 128v256z"
          ></path>
        </svg>
        <span class="main-text09">Sedes</span>
        <h1 class="main-text10">10</h1>
      </div>
      <div class="main-stat3">
        <svg viewBox="0 0 1152 1024" class="main-icon08">
          <path
            d="M768 770.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"
          ></path>
          <path
            d="M327.196 795.328c55.31-36.15 124.080-63.636 199.788-80.414-15.054-17.784-28.708-37.622-40.492-59.020-30.414-55.234-46.492-116.058-46.492-175.894 0-86.042 0-167.31 30.6-233.762 29.706-64.504 83.128-104.496 159.222-119.488-16.914-76.48-61.94-126.75-181.822-126.75-192 0-192 128.942-192 288 0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h279.006c14.518-12.91 30.596-25.172 48.19-36.672z"
          ></path>
        </svg>
        <span class="main-text11">Funcionarios</span>
        <h1 class="main-text12">+50</h1>
      </div>
    </div>
    <div class="main-features1">
      <h1 class="main-text13">Descubre Nuestras Modalidades</h1>
      <div class="main-container2">
        <img alt="image" src="/descubre-1400w.png" class="main-image" />
        <div class="main-container3">
          <app-modalidad
            title="Patronaje Industrial de Prendas de Vestir"
            image_src="/adobestock_585062461.svg"
            description="Únete a nosotros para aprender más sobre esta emocionante disciplina que da forma a la moda"
            rootClassName="rootClassName1"
          ></app-modalidad>
          <app-modalidad
            title="Mecánico de Maquinaria Industrial"
            image_src="/copia%20de%20adobestock_585062461.svg"
            description="Descubre los secretos detrás del funcionamiento de las máquinas que impulsan la industria"
            rootClassName="rootClassName4"
          ></app-modalidad>
          <app-modalidad
            title="Contabilización de Operaciones Comerciales y Financieras"
            image_src="/adobestock_541220524.svg"
            description="Explora y profundiza en el fascinante mundo de la contabilidad empresarial."
            rootClassName="rootClassName3"
          ></app-modalidad>
        </div>
        <img alt="image" src="/descubre-1400w.png" class="main-image1" />
      </div>
    </div>
    <div class="main-testimonial">
      <div class="main-container4">
        <h1 class="main-text14">
          <span>Lo que están diciendo </span>
          <br />
        </h1>
        <div class="main-container5">
          <div class="main-testimonial1">
            <div class="main-testimonial2">
              <div class="main-container6">
                <svg viewBox="0 0 950.8571428571428 1024" class="main-icon11">
                  <path
                    d="M438.857 548.571v219.429c0 60.571-49.143 109.714-109.714 109.714h-219.429c-60.571 0-109.714-49.143-109.714-109.714v-402.286c0-161.143 131.429-292.571 292.571-292.571h36.571c20 0 36.571 16.571 36.571 36.571v73.143c0 20-16.571 36.571-36.571 36.571h-36.571c-80.571 0-146.286 65.714-146.286 146.286v18.286c0 30.286 24.571 54.857 54.857 54.857h128c60.571 0 109.714 49.143 109.714 109.714zM950.857 548.571v219.429c0 60.571-49.143 109.714-109.714 109.714h-219.429c-60.571 0-109.714-49.143-109.714-109.714v-402.286c0-161.143 131.429-292.571 292.571-292.571h36.571c20 0 36.571 16.571 36.571 36.571v73.143c0 20-16.571 36.571-36.571 36.571h-36.571c-80.571 0-146.286 65.714-146.286 146.286v18.286c0 30.286 24.571 54.857 54.857 54.857h128c60.571 0 109.714 49.143 109.714 109.714z"
                  ></path>
                </svg>
              </div>
              <span class="main-text17">
                La Institución garantiza a la comunidad educativa la articulación
                de la educación media con la educación superior, para el trabajo y
                el desarrollo humano buscando que los jóvenes fortalezcan sus
                competencias básicas, ciudadanas y desarrollen las competencias
                especificas necesarias para continuar su formación a lo largo de
                toda la vida e insertarse competitivamente en el mundo del trabajo
                o iniciar un programa de educación superior.
              </span>
              <div class="main-container7">
                <svg viewBox="0 0 950.8571428571428 1024" class="main-icon13">
                  <path
                    d="M438.857 182.857v402.286c0 161.143-131.429 292.571-292.571 292.571h-36.571c-20 0-36.571-16.571-36.571-36.571v-73.143c0-20 16.571-36.571 36.571-36.571h36.571c80.571 0 146.286-65.714 146.286-146.286v-18.286c0-30.286-24.571-54.857-54.857-54.857h-128c-60.571 0-109.714-49.143-109.714-109.714v-219.429c0-60.571 49.143-109.714 109.714-109.714h219.429c60.571 0 109.714 49.143 109.714 109.714zM950.857 182.857v402.286c0 161.143-131.429 292.571-292.571 292.571h-36.571c-20 0-36.571-16.571-36.571-36.571v-73.143c0-20 16.571-36.571 36.571-36.571h36.571c80.571 0 146.286-65.714 146.286-146.286v-18.286c0-30.286-24.571-54.857-54.857-54.857h-128c-60.571 0-109.714-49.143-109.714-109.714v-219.429c0-60.571 49.143-109.714 109.714-109.714h219.429c60.571 0 109.714 49.143 109.714 109.714z"
                  ></path>
                </svg>
              </div>
            </div>
            <span class="main-text18">Docentes</span>
            <span class="main-text19">Area Técnica</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main-features2">
      <h1 class="main-text20">Descubre</h1>
      <div class="main-container8">
        <router-link to="/normativa">
          <app-descubre
            title="Manual de Convivencia"
            image_src="/manual.svg"
            rootClassName="rootClassName"
            class="main-component07"
          ></app-descubre>
        </router-link>
        <router-link to="/calendario">
          <app-descubre
            title="Calendario"
            image_src="/calendario.svg"
            rootClassName="rootClassName1"
            class="main-component08"
          ></app-descubre>
        </router-link>
        <router-link to="/galeria">
          <app-descubre
            title="Galeria"
            image_src="/galeria.svg"
            rootClassName="rootClassName2"
            class="main-component09"
          ></app-descubre>
        </router-link>
        <router-link to="/secciones">
          <app-descubre
            title="Sedes"
            image_src="/sedes.svg"
            rootClassName="rootClassName3"
            class="main-component10"
          ></app-descubre>
        </router-link>
        <router-link to="/modalidades">
          <app-descubre
            title="Areas Técnicas"
            image_src="/modalidad.svg"
            rootClassName="rootClassName7"
            class="main-component11"
          ></app-descubre>
        </router-link>
        <router-link to="/docentes">
          <app-descubre
            title="Docentes"
            image_src="/docentes.svg"
            rootClassName="rootClassName6"
            class="main-component12"
          ></app-descubre>
        </router-link>
        <router-link to="/normativa">
          <app-descubre
            title="Normativa"
            image_src="/normassvg.svg"
            rootClassName="rootClassName5"
            class="main-component13"
          ></app-descubre>
        </router-link>
        <a
          href="https://accounts.google.com/signin/v2/identifier?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&amp;service=mail&amp;hd=institucioneducativajgmm.edu.co&amp;sacu=1&amp;flowName=GlifWebSignIn&amp;flowEntry=AddSession&amp;theme=glif"
          target="_blank"
          rel="noreferrer noopener"
        >
          <app-descubre
            title="Correo Institucional"
            image_src="/correo.svg"
            rootClassName="rootClassName4"
            class="main-component14"
          ></app-descubre>
        </a>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppNoticias from '../components/noticias'
import AppModalidad from '../components/modalidad'
import AppDescubre from '../components/descubre'
import AppFooter from '../components/footer'

export default {
  name: 'Main',
  components: {
    AppHeader,
    AppNoticias,
    AppModalidad,
    AppDescubre,
    AppFooter,
  },
  metaInfo: {
    title: 'ColegioTasco',
    meta: [
      {
        property: 'og:title',
        content: 'ColegioTasco',
      },
    ],
  },
}
</script>

<style scoped>
.main-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.main-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: 100%;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/cole-1500h.jpg");
  background-position: center;
}
.main-text {
  color: var(--dl-color-gray-white);
  width: 60%;
  font-size: 3rem;
  text-align: center;
}
.main-btn-group {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.main-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.main-text01 {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.main-text04 {
  color: var(--dl-color-gray-700);
  width: 70%;
  font-size: 1.15rem;
  text-align: center;
}
.main-container1 {
  width: auto;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-self: center;
  margin-top: var(--dl-space-space-fourunits);
  grid-template-columns: 1fr 1fr 1fr;
}
.main-stats {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 100%;
  justify-content: center;
  background-color: var(--dl-color-main-primary);
}
.main-stat {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  flex-direction: column;
}
.main-icon {
  fill: var(--dl-color-text-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.main-text05 {
  color: var(--dl-color-text-white);
  font-size: 2rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.main-text06 {
  color: var(--dl-color-text-white);
  font-size: 3rem;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 300;
}
.main-stat1 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  flex-direction: column;
}
.main-icon03 {
  fill: var(--dl-color-text-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.main-text07 {
  color: var(--dl-color-text-white);
  font-size: 2rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.main-text08 {
  color: var(--dl-color-text-white);
  font-size: 3rem;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 300;
}
.main-stat2 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  flex-direction: column;
}
.main-icon06 {
  fill: var(--dl-color-text-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.main-text09 {
  color: var(--dl-color-text-white);
  font-size: 2rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.main-text10 {
  color: var(--dl-color-text-white);
  font-size: 3rem;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 300;
}
.main-stat3 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  flex-direction: column;
}
.main-icon08 {
  fill: var(--dl-color-text-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.main-text11 {
  color: var(--dl-color-text-white);
  font-size: 2rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.main-text12 {
  color: var(--dl-color-text-white);
  font-size: 3rem;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 300;
}
.main-features1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-default);
  flex-direction: column;
}
.main-text13 {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.main-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.main-image {
  width: 450px;
  height: 450px;
  display: none;
  object-fit: contain;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-round);
  object-position: center;
}
.main-container3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.main-image1 {
  width: 450px;
  height: 450px;
  object-fit: contain;
  flex-shrink: 0;
  margin-left: 0px;
  border-radius: var(--dl-radius-radius-round);
  object-position: center;
}
.main-testimonial {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #485158;
}
.main-container4 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.main-text14 {
  color: var(--dl-color-gray-white);
  font-size: 3em;
}
.main-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.main-testimonial1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.main-testimonial2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.main-container6 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.main-icon11 {
  fill: var(--dl-color-text-white);
  width: var(--dl-size-size-small);
  margin-bottom: -6rem;
}
.main-text17 {
  color: var(--dl-color-text-white);
  font-size: 1.15rem;
  max-width: 90%;
  margin-top: var(--dl-space-space-threeunits);
  text-align: center;
  padding-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-twounits);
}
.main-container7 {
  display: flex;
  align-self: flex-end;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.main-icon13 {
  fill: var(--dl-color-text-white);
  width: var(--dl-size-size-small);
  margin-top: -6rem;
}
.main-text18 {
  color: var(--dl-color-text-white);
  font-size: 1.5rem;
  max-width: 600px;
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.main-text19 {
  color: var(--dl-color-text-white);
  max-width: 600px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.main-features2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.main-text20 {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-threeunits);
}
.main-container8 {
  flex: 0 0 auto;
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.main-component07 {
  text-decoration: none;
}
.main-component08 {
  text-decoration: none;
}
.main-component09 {
  text-decoration: none;
}
.main-component10 {
  text-decoration: none;
}
.main-component11 {
  text-decoration: none;
}
.main-component12 {
  text-decoration: none;
}
.main-component13 {
  text-decoration: none;
}
.main-component14 {
  text-decoration: none;
}
@media(max-width: 1440px) {
  .main-text17 {
    max-width: 90%;
  }
}
@media(max-width: 1366px) {
  .main-features {
    max-width: var(--dl-size-size-default);
  }
  .main-text14 {
    color: var(--dl-color-gray-white);
  }
  .main-text17 {
    color: var(--dl-color-text-white);
    font-size: 1.5rem;
    max-width: 90%;
  }
}
@media(max-width: 991px) {
  .main-hero {
    flex-direction: column;
  }
  .main-text {
    text-align: center;
  }
  .main-text01 {
    text-align: center;
  }
  .main-text04 {
    text-align: center;
  }
  .main-container1 {
    grid-template-columns: 1fr;
  }
  .main-features1 {
    align-items: center;
  }
  .main-container2 {
    flex-direction: column;
  }
  .main-image {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    border-radius: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .main-image1 {
    width: 100%;
    height: auto;
    display: none;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    border-radius: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .main-text14 {
    text-align: center;
  }
  .main-text17 {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .main-text20 {
    align-self: center;
  }
  .main-container8 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .main-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .main-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .main-text01 {
    text-align: center;
  }
  .main-stats {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .main-stat {
    padding: var(--dl-space-space-unit);
  }
  .main-text05 {
    text-align: center;
  }
  .main-stat1 {
    padding: var(--dl-space-space-unit);
  }
  .main-text07 {
    text-align: center;
  }
  .main-stat2 {
    padding: var(--dl-space-space-unit);
  }
  .main-text09 {
    text-align: center;
  }
  .main-stat3 {
    padding: var(--dl-space-space-unit);
  }
  .main-text11 {
    text-align: center;
  }
  .main-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .main-text13 {
    text-align: center;
  }
  .main-container2 {
    flex-direction: column;
  }
  .main-container4 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .main-testimonial1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .main-features2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 720px) {
  .main-image {
    width: 100%;
    height: auto;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .main-container3 {
    grid-template-columns: 1fr;
  }
  .main-image1 {
    width: 100%;
    height: auto;
    margin-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .main-hero {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .main-btn-group {
    flex-direction: column;
  }
  .main-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .main-container1 {
    grid-template-columns: 1fr;
  }
  .main-stats {
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .main-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .main-image {
    width: 250px;
    height: 250px;
  }
  .main-container3 {
    grid-template-columns: 1fr;
  }
  .main-image1 {
    width: 250px;
    height: 250px;
  }
  .main-container4 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .main-testimonial1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .main-icon11 {
    margin-bottom: 0px;
  }
  .main-text17 {
    width: auto;
  }
  .main-icon13 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .main-features2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .main-container8 {
    grid-gap: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 393px) {
  .main-stats {
    flex-direction: column;
  }
  .main-container2 {
    margin-top: 0px;
  }
  .main-image {
    width: 100%;
    height: auto;
    border-radius: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .main-image1 {
    width: 100%;
    height: auto;
    border-radius: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .main-container4 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .main-testimonial1 {
    padding: 0px;
  }
  .main-text17 {
    width: 100%;
    font-size: 1.2rem;
    margin-top: var(--dl-space-space-unit);
    text-align: center;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .main-container8 {
    grid-template-columns: 1fr;
  }
}
</style>
