<template>
  <div class="docentes-container">
    <app-header rootClassName="header-root-class-name7"></app-header>
    <div class="docentes-testimonial">
      <div class="docentes-container1">
        <h1 class="docentes-text">
          <span>Conoce a nuestros docentes</span>
          <br />
        </h1>
        <div class="docentes-container2">
          <app-docente rootClassName="docente-root-class-name3"></app-docente>
          <app-docente rootClassName="docente-root-class-name18"></app-docente>
          <app-docente rootClassName="docente-root-class-name19"></app-docente>
          <app-docente rootClassName="docente-root-class-name14"></app-docente>
          <app-docente rootClassName="docente-root-class-name10"></app-docente>
          <app-docente rootClassName="docente-root-class-name22"></app-docente>
          <app-docente rootClassName="docente-root-class-name6"></app-docente>
          <app-docente rootClassName="docente-root-class-name26"></app-docente>
          <app-docente rootClassName="docente-root-class-name23"></app-docente>
          <app-docente rootClassName="docente-root-class-name15"></app-docente>
          <app-docente rootClassName="docente-root-class-name11"></app-docente>
          <app-docente rootClassName="docente-root-class-name7"></app-docente>
          <app-docente rootClassName="docente-root-class-name4"></app-docente>
          <app-docente rootClassName="docente-root-class-name24"></app-docente>
          <app-docente rootClassName="docente-root-class-name20"></app-docente>
          <app-docente rootClassName="docente-root-class-name16"></app-docente>
          <app-docente rootClassName="docente-root-class-name12"></app-docente>
          <app-docente rootClassName="docente-root-class-name8"></app-docente>
          <app-docente rootClassName="docente-root-class-name5"></app-docente>
          <app-docente rootClassName="docente-root-class-name25"></app-docente>
          <app-docente rootClassName="docente-root-class-name21"></app-docente>
          <app-docente rootClassName="docente-root-class-name17"></app-docente>
          <app-docente rootClassName="docente-root-class-name13"></app-docente>
          <app-docente rootClassName="docente-root-class-name9"></app-docente>
        </div>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name1"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppDocente from '../components/docente'
import AppFooter from '../components/footer'

export default {
  name: 'Docentes',
  components: {
    AppHeader,
    AppDocente,
    AppFooter,
  },
  metaInfo: {
    title: 'Docentes - ColegioTasco',
    meta: [
      {
        property: 'og:title',
        content: 'Docentes - ColegioTasco',
      },
    ],
  },
}
</script>

<style scoped>
.docentes-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.docentes-testimonial {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.docentes-container1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-default);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.docentes-text {
  font-size: 3em;
  margin-bottom: var(--dl-space-space-threeunits);
}
.docentes-container2 {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media(max-width: 1366px) {
  .docentes-text {
    color: var(--dl-color-gray-black);
  }
}
@media(max-width: 991px) {
  .docentes-text {
    color: var(--dl-color-gray-black);
    text-align: center;
  }
  .docentes-container2 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .docentes-container1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 720px) {
  .docentes-text {
    color: var(--dl-color-gray-black);
  }
}
@media(max-width: 479px) {
  .docentes-container1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 393px) {
  .docentes-container1 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .docentes-text {
    color: var(--dl-color-gray-black);
  }
  .docentes-container2 {
    grid-template-columns: 1fr;
  }
}
</style>
